import { useNavigate } from 'react-router-dom';

import { ProfileSvg, ThreeDotsSvg } from 'react-gldn-kit';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserNameSelector } from 'stores/user/personal/selectors';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { getBalanceInfoSelector } from 'stores/user/balance/selectors';

import { ERouteNames } from 'Routes/types';

import styles from './DesktopProfileView.module.css';

const DesktopProfile = () => {
  const username = useZustandUserStore(getUserNameSelector);

  const balance = useZustandBalanceStore(getBalanceInfoSelector);

  const navigate = useNavigate();

  const jumpToProfile = () => {
    navigate(ERouteNames.ProfileAccount);
  };

  return (
    <div className={styles.wrapperDesktopProfileView}>
      <div className={styles.profileIcon}>
        <ProfileSvg />
      </div>
      <div className={styles.profileContent}>
        <div>
          <div className={styles.username}>{username}</div>
          {/* <div className={styles.loyaltyLvl}> */}
          {/* <Text idT="bonusCabinet.tabs.loyalty" />: */}
          {/* {balance?.loyaltyLvl} */}
          {/* </div> */}
        </div>

        <div className={styles.threeDots} onClick={jumpToProfile}>
          <ThreeDotsSvg />
        </div>
      </div>
    </div>
  );
};

export default DesktopProfile;
