import { useEffect } from 'react';

import { SearchSvg } from 'react-gldn-kit';
import Input from 'components/ui/Input/Base';

import { useZustandCasinoSearch } from 'stores/casino/search';
import { setCasinoSearchValueAction } from 'stores/casino/search/selectors';

import styles from './CasinoSearchInput.module.css';

type Props = {
  classes?: string;
};

const CasinoSearchInput = ({ classes }: Props) => {
  const setSearchValue = useZustandCasinoSearch(setCasinoSearchValueAction);

  useEffect(
    () => () => {
      setSearchValue('');
    },
    []
  );

  return (
    <Input
      size="full"
      placeholder="search"
      onChange={setSearchValue}
      isHidePlaceholder
      classes={classes}
    >
      <SearchSvg classes={styles.searchIcon} />
    </Input>
  );
};

export default CasinoSearchInput;
