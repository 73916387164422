import React from 'react';
import { useBetSlipSpecialOdd } from 'react-render-hooks';

import { useHookFabric } from 'hooks/useHookFabric';

const SystemOdd = () => {
  const odd = useHookFabric<{
    system: string;
  }>({
    fn: useBetSlipSpecialOdd,
    customProps: { systemOdd: true },
  });

  return <div>{odd?.system}</div>;
};

export default SystemOdd;
