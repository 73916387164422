import {
  useWeeklyBonus,
  useBonuses,
  useRakeBack,
  useAuth,
} from 'react-render-hooks';
import {
  ReturnTypeRakeBake,
  ReturnTypeWeeklyBonus,
} from 'react-memory-optimization/dist/lib/store/operations/entities/vipClub/types';
import { DrawerButton, useTranslate, BonusGiftSvg } from 'react-gldn-kit';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BonusButtons.module.css';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { BonusesResponse } from 'react-memory-optimization/dist/lib/store/operations/types';

const BonusButtons = () => {
  const closeDrawer = useZustandModalStore(closeAndOpenModalAction);

  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const { getText } = useTranslate('', '');

  const { bonusOnDeposit = [], freeSpins = [] } =
    useHookFabric<BonusesResponse>({
      fn: useBonuses,
      customProps: {
        isActive: true,
      },
    }) || {};

  const weekly = useHookFabric<ReturnTypeWeeklyBonus>({
    fn: useWeeklyBonus,
  });

  const rakebackInfo = useHookFabric<ReturnTypeRakeBake>({
    fn: useRakeBack,
  });

  const openModal = (t: ModalTypes) => () => {
    closeDrawer(t);
  };

  const count =
    Number(Boolean(rakebackInfo && rakebackInfo.rakeback)) +
    Number(Boolean(weekly && weekly.amount)) +
    bonusOnDeposit.length +
    freeSpins.length;

  return (
    <DrawerButton
      size="full"
      text={getText('drawers.casino.bonuses')}
      handleClick={openModal(isAuth ? ModalTypes.Bonuses : ModalTypes.SignIn)}
      startIcon={<BonusGiftSvg classes={styles.giftSvg} />}
      classes={styles.marginBottom}
      isFullView={isFullView}
    >
      {count ? <div className={styles.red}>{count}</div> : undefined}
    </DrawerButton>
  );
};

export default BonusButtons;
