import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { CloseSvg } from 'react-gldn-kit';
import CircularLoader from 'components/ui/Loaders/Circular';

import {
  getRedirectUrlSelector,
  setPaymentsCurrentStepAction,
} from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { Steps } from 'stores/money/payments/types';
import { CASINO_INSIDE_CASINO } from 'constants/transport';

import styles from './PaymentFrame.module.css';

const PaymentFrame = () => {
  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const frameUrl = useZustandPaymentsStore(getRedirectUrlSelector);

  const onClose = () => {
    setStep(Steps.ChooseMethod);
  };

  const getMessage = ({ data }: { data: any }) => {
    if (data === CASINO_INSIDE_CASINO) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('message', getMessage, false);

    return () => {
      window.removeEventListener('message', getMessage, false);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.wrapperPaymentFrame}>
      <CloseSvg classes={styles.closeBtn} handleClick={onClose} />

      {frameUrl ? (
        <iframe src={frameUrl} className={styles.frame} />
      ) : (
        <CircularLoader />
      )}
    </div>,
    document.body
  );
};

export default PaymentFrame;
