import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';

import styles from '../Withdraw.module.css';
import PhoneInput from 'components/ui/Input/Phone';

import { useZustandFormsStore } from 'stores/money/forms/store';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { Steps } from 'stores/money/payments/types';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';

type Props = {
  min: number;
  max: number;
  channelId:
    | PaymentChannelType.BKash
    | PaymentChannelType.ApayBkash
    | PaymentChannelType.Nagad
    | PaymentChannelType.ApayNagad
    | PaymentChannelType.Rocket
    | PaymentChannelType.OctopayRocket
    | PaymentChannelType.OctopayUPay
    | PaymentChannelType.OctopayBKash
    | PaymentChannelType.VivaroNagad
    | PaymentChannelType.VivaroBkash
    | PaymentChannelType.OctopayNagad
    | PaymentChannelType.EasypaisaFast
    | PaymentChannelType.Easypaisa
    | PaymentChannelType.UPay
    | PaymentChannelType.JazzCash;
};

const DefaultWithdraw = (props: Props) => {
  const { min, max, channelId } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState('');

  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (amount && currencyId && phone) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          phone,
          channelId,
          currencyId,
          requestId: ERequestIds.BKashWithdraw,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId, phone, channelId]);

  return (
    <div>
      <AmountInput
        isWithdraw
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      <PhoneInput
        countryCodeDefault="BD"
        withConfirm={false}
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        sendKey="09x"
        defaultValue="+880"
        onChange={setPhone}
        baseValue="+880"
        classesWrapper={styles.marginInputBottom}
        placeholder="walletPopUp.forms.phone"
      />
    </div>
  );
};

export default DefaultWithdraw;
