import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  useHasDemoModeGame,
  useTransportControllers,
} from 'react-render-hooks';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import {
  Text,
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'react-gldn-kit';
import PlayButton from 'components/ui/Buttons/PlayButton';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import DefaultGameImg from 'components/ui/DefaultGameImg';
import HOCImageWithDefault from 'components/HOCs/HOCImageWithDefault';
import MobileLaunchGamePopUp from './components/MobileLaunchGamePopUp';
import DesktopLaunchGamePopUp from './components/DesktopLaunchGamePopUp';

import { IS_VALID_MOBILE_APP } from 'constants/marketingTags';
import { IS_DEVELOPMENT_MODE } from 'constants/ui';

import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import {
  getCasinoGameUrlSelector,
  setCasinoGameUrlAction,
} from 'stores/casino/games/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { CASINO_INSIDE_CASINO } from 'constants/transport';
import { useZustandMarketingTabsStore } from 'stores/marketing/store';
import { isValidMobileAppSelector } from 'stores/marketing/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { ENotificationType } from 'stores/notification/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoLaunchGame.module.css';
import { AdaptedGame } from 'react-memory-optimization/dist/lib/store/casino/entities/games/types';

const LaunchGame = (props: AdaptedGame) => {
  const { id, name, imageUrl, providerName, providerId } = props;

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const hasDemo = useHookFabric<boolean>({
    fn: useHasDemoModeGame,
    customProps: {
      gameId: id,
    },
  });

  const language = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const setCasinoGameUrl = useZustandCasinoGamesStore(setCasinoGameUrlAction);

  const casinoUrl = useZustandCasinoGamesStore(getCasinoGameUrlSelector);

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const { sendCommand } = useTransportControllers();

  const isValidMobileApp = useZustandMarketingTabsStore(
    isValidMobileAppSelector
  );

  const getMessage = ({ data }: { data: any }) => {
    if (data === CASINO_INSIDE_CASINO) {
      setCasinoGameUrl('');

      addNotification({
        uid: `casino_game_failed+${new Date().getTime()}`,
        text: 'errors.gameFailed',
        type: ENotificationType.Warning,
      });
    }
  };

  const handleRunCasinoGame =
    (isDemo = false) =>
    () => {
      const key = isDemo ? 'x04' : '17x';
      const requestId = isDemo
        ? ERequestIds.InitCasinoDemo
        : ERequestIds.InitCasinoGame;

      if (!language) {
        return;
      }

      const returnUrl = isValidMobileApp
        ? `${window.location.origin}/?${IS_VALID_MOBILE_APP}=1`
        : window.location.origin;

      sendCommand(
        key,
        {
          gameId: id,
          language,
          returnUrl,
          requestId,
        },
        true
      );
    };

  useEffect(
    () => () => {
      setCasinoGameUrl('');
    },
    []
  );

  useEffect(() => {
    if (casinoUrl) {
      // toggleHeaderState(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      // toggleHeaderState(false);
    }
  }, [casinoUrl]);

  useEffect(() => {
    window.addEventListener('message', getMessage, false);

    return () => {
      window.removeEventListener('message', getMessage, false);
    };
  }, []);

  return (
    <div className={styles.wrapperLaunchGame}>
      <HOCImageWithDefault
        src={IS_DEVELOPMENT_MODE ? `https://elondom.com${imageUrl}` : imageUrl}
        classes={styles.gameImg}
      >
        <DefaultGameImg classes={styles.gameImg} gameName={name} />
      </HOCImageWithDefault>

      {casinoUrl &&
        ReactDOM.createPortal(
          isMobile ? (
            <MobileLaunchGamePopUp />
          ) : (
            <DesktopLaunchGamePopUp
              providerName={providerName}
              providerId={providerId}
            />
          ),
          document.body
        )}

      <div className={styles.rowCenter}>
        <PlayButton
          handleClick={handleRunCasinoGame()}
          classes={styles.iconMrg}
        />

        <div className={styles.playText}>
          <Text idT="play" />
        </div>

        {!!hasDemo && (
          <HOCRequestLoader
            requestId={ERequestIds.InitCasinoDemo}
            command="\\x04"
          >
            <ComponentKitBaseButton
              color="dark"
              size="--button-xl"
              buttonHeight="--button-height-xs"
              text="demoPlay"
              handleClick={handleRunCasinoGame(true)}
              centreText
            />
          </HOCRequestLoader>
        )}
      </div>
    </div>
  );
};

export default LaunchGame;
