import cln from 'classnames';
import { EAppType } from 'react-gldn-kit/dist/lib/types/ui';

import { DrawerTogglerSvg } from 'react-gldn-kit';
import ComponentKitLogo from 'components/ui/KitComponents/ComponentKitLogo';

import { APP_TYPE } from 'constants/ui';
import {
  isFullCasinoDrawerSelector,
  setFullCasinoDrawerAction,
} from 'stores/drawers/casino/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { useZustandRightSideStore } from 'stores/drawers/rightSide/store';
import {
  isFullRightSideSelector,
  setRightSideDrawerViewAction,
} from 'stores/drawers/rightSide/selectors';

import {
  isDesktopDeviceSelector,
  isMobileDeviceSelector,
} from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';

import styles from './LeftSection.module.css';

const HeaderLeftSection = () => {
  const isDesktop = useZustandDeviceStore(isDesktopDeviceSelector);

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const isView = useZustandRightSideStore(isFullRightSideSelector);

  const toggleCasinoDrawerState = useZustandCasinoDrawerStore(
    setFullCasinoDrawerAction
  );

  const setRightSideDrawerView = useZustandRightSideStore(
    setRightSideDrawerViewAction
  );

  const toggleDrawerState = () => {
    toggleCasinoDrawerState();
  };

  const toggleRightSideState = () => {
    setRightSideDrawerView();
  };

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  if (isMobile) {
    //
    return <ComponentKitLogo customIsMobile={APP_TYPE === EAppType.JabiBet} />;
  }

  return (
    <>
      <div
        className={cln(styles.drawerStateDrawerButton, styles.stateButton, {
          [styles.hideLeftSide]: isFullView,
        })}
        onClick={toggleDrawerState}
      >
        <DrawerTogglerSvg classes={styles.drawerTogglerSvg} />
      </div>

      {/* {isDesktop && (
        <div
          className={cln(
            styles.drawerStateTournamentButton,
            styles.stateButton,
            {
              [styles.hideRightSide]: isView,
            }
          )}
          onClick={toggleRightSideState}
        >
          <DrawerTogglerSvg classes={styles.drawerTogglerSvg} />
        </div>
      )} */}
    </>
  );
};

export default HeaderLeftSection;
