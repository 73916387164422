import cln from 'classnames';

import { Text, CloseSvg } from 'react-gldn-kit';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './ModalTitle.module.css';

type Props = {
  name: string;
  startIcon?: React.ReactElement;
  classes?: string;
  notClosed?: boolean;
  closeBtnFn?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const ModalTitle = (props: Props) => {
  const { name, startIcon, classes, notClosed = false, closeBtnFn } = props;

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const onClose = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (closeBtnFn) {
      closeBtnFn(e);

      return;
    }
    setModal(ModalTypes.Unknown);
  };

  return (
    <div className={cln(styles.titleWrapper, classes)}>
      <div className={styles.row}>
        {startIcon}

        <div className={cln(styles.title, { [styles.marginL]: startIcon })}>
          <Text idT={name} />
        </div>
      </div>
      {!notClosed && <CloseSvg handleClick={onClose} />}
    </div>
  );
};

export default ModalTitle;
