import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import InrDefaultDeposit from './components/InrDefaultDeposit';

import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getActivePaymentInfoSelector } from 'stores/money/payments/selectors';

type Props = {
  channelId: PaymentChannelType.InrDeposit; // 144
  limit: { min: number; max: number };
};

const InrDeposit = (props: Props) => {
  const { channelId, limit } = props;

  const activePayment = getActivePaymentInfoSelector(
    useZustandPaymentsStore.getState()
  );

  if (!activePayment) {
    return null;
  }

  if (activePayment.channelName === 'PhonePe Fast') {
    return (
      <InrDefaultDeposit limit={limit} requestId={ERequestIds.DepositPhonePe} />
    );
  }

  if (activePayment.channelName === 'PayTM Fast') {
    return (
      <InrDefaultDeposit
        limit={limit}
        requestId={ERequestIds.DepositPayTm}
        //
      />
    );
  }

  if (activePayment.channelName === 'GooglePay') {
    return (
      <InrDefaultDeposit
        limit={limit}
        requestId={ERequestIds.GoogleInrDeposit}
      />
    );
  }

  return null;
};

export default InrDeposit;
