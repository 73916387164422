import cln from 'classnames';
import { useBettingSports } from 'react-render-hooks';
import { Sport } from 'react-memory-optimization/dist/lib/store/betting/entities/sports/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { getSportIcon } from 'utils/icons';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './ListSports.module.css';

type Props = {
  handleChooseSport: (id: number) => () => void;
  isShortView?: boolean;
  chooseSportId: number | null;
  isPrematch: boolean;
};

const ListSports = (props: Props) => {
  const { handleChooseSport, isShortView, chooseSportId, isPrematch } = props;

  const sports = useHookFabric<Sport[]>({
    fn: useBettingSports,
    customProps: { isPrematch },
  });

  return (
    <>
      {sports?.map((s: any) => (
        <ComponentKitBaseButton
          color="secondary"
          text={isShortView ? '' : s.name}
          size={isShortView ? '--button-m' : '--button-full'}
          buttonHeight="--button-height-l"
          key={s.id}
          handleClick={handleChooseSport(s.id)}
          startIcon={getSportIcon(s.id)}
          classes={cln(styles.itemSportWrapper, {
            [styles.shortView]: isShortView,
            [styles.activeSport]: s.id === chooseSportId,
          })}
        />
      ))}
    </>
  );
};

export default ListSports;
