import { create } from 'zustand';
import { ZustandFormsStore } from './types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

export const useZustandFormsStore = create<ZustandFormsStore>((set) => ({
  formInfo: null,
  bonusIdForActivate: null,
  loaderInfo: {
    command: '',
    requestId: ERequestIds.Unknown, // TODO added Unknown
  },
  bdtStepTwo: null,
  setBdtTwpStepInfo: (bdtStepTwo) => {
    set({ bdtStepTwo });
  },

  setLoaderInfo: (loaderInfo) => {
    set({ loaderInfo });
  },

  setBonusIdForActivate: (bonusIdForActivate) => {
    set({ bonusIdForActivate });
  },
  setFormInfo: (formInfo) => {
    set({ formInfo });
  },
}));
