import { useBattles } from 'react-render-hooks';
import { ModalLayout, useTranslate } from 'react-gldn-kit';
import { BattleHookInfo } from 'react-memory-optimization/dist/lib/store/casino/entities/casinoTournaments/types';

import TournamentPlayerRow from 'components/ui/TournamentPlayer';

import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandModalStore } from 'stores/modal/store';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoTournamentPlayers.module.css';

const CasinoTournamentPlayers = () => {
  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const battles = useHookFabric<BattleHookInfo[]>({
    fn: useBattles,
    customProps: {
      isFinished: false,
    },
  });

  const { name: title } = useTranslate('tournament.players.title', '');

  const onClose = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
      }}
      buttonClose={onClose}
    >
      <>
        <div className={styles.playerContent}>
          {battles?.[0].players?.map((player, inx) => (
            <div key={player.id} className={styles.wrapperPlayer}>
              {/* <TournamentPlayerRow position={inx + 1} {...player} /> */}
            </div>
          ))}
        </div>
      </>
    </ModalLayout>
  );
};

export default CasinoTournamentPlayers;
