import { useEffect, useState } from 'react';
import {
  useGetActiveTournamentPlayers,
  // useGetActiveTournamentInfo,
} from 'react-render-hooks';
import {
  AdaptedCasinoTournament,
  TournamentPlayer,
} from 'react-memory-optimization/dist/lib/store/casino/entities/casinoTournaments/types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import TournamentCardComponentKit from 'components/ui/KitComponents/TournamentCard';
import CasinoTournamentPlayer from 'components/ui/TournamentPlayer';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import CasinoSignInTournamentPlayer from '../CasinoSignInTournamentPlayer';
import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import { DEFAULT_TOP_PLAYERS, DEFAULT_TOURNAMENT } from './constants';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoTournament.module.css';

const CasinoTournament = () => {
  const [topPlayers, setPlayers] =
    useState<TournamentPlayer[]>(DEFAULT_TOP_PLAYERS);

  const setModalInfo = useZustandModalStore(closeAndOpenModalAction);

  // const tournamentInfo = useHookFabric<any>({
  //   fn: useGetActiveTournamentInfo,
  // }) as null | AdaptedCasinoTournament;

  // const players = useHookFabric<any>({
  //   fn: useGetActiveTournamentPlayers,
  // }) as TournamentPlayer[];

  const toggleShowMoreState = () => {
    setModalInfo(ModalTypes.CasinoTournamentPlayers);
  };

  // useEffect(() => {
  //   if (players.length) {
  //     setPlayers(players.slice(0, 3));
  //   }
  // }, [players]);

  return (
    <HOCRequestLoader
      requestId={ERequestIds.CasinoTournaments}
      command="\\36x"
      customLoader={<SkeletLoader classes={styles.skeletWrapper} />}
    >
      <div className={styles.wrapperCasinoTournament}>
        <div className={styles.wrapperPlayer}>
          {topPlayers.map((player, inx) => (
            <CasinoTournamentPlayer
              classes={styles.wrapperPlayer}
              key={player.id}
              position={inx + 1}
              {...player}
            />
          ))}
        </div>

        <div className={styles.dash} />

        <div className={styles.wrapperPlayer}></div>
      </div>
    </HOCRequestLoader>
  );
};

export default CasinoTournament;
