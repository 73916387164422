import { useShortLineInfo, useBetSlipControllers } from 'react-render-hooks';
import { ReturnSubscribeLineEntitiesProps } from 'react-memory-optimization/dist/lib/store/betting/entities/events/types';

import { CloseSvg } from 'react-gldn-kit';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './EventInfo.module.css';

type Props = {
  isPrematch: boolean;
  eventUid: string;
  betUid: string;
  lineUid: string;
  outcomeId: number;
};

const EventInfo = (props: Props) => {
  const { isPrematch, eventUid, lineUid, outcomeId, betUid } = props;

  const shortLineInfo = useHookFabric<ReturnSubscribeLineEntitiesProps>({
    fn: useShortLineInfo,
    customProps: {
      lineUid,
      eventUid,
      isPrematch,
      outcomeId,
    },
  });

  const { removeBet }: any = useHookFabric<any>({ fn: useBetSlipControllers });

  return (
    <div className={styles.ordinarInfo}>
      <CloseSvg classes={styles.closeSvg} handleClick={removeBet({ betUid })} />

      <div>
        <div className={styles.nameEvent}>{shortLineInfo?.favoriteTeam}</div>
        <div className={styles.marketName}>{shortLineInfo?.marketName}</div>
      </div>
      <div className={styles.teamNames}>
        {shortLineInfo?.homeName}-{shortLineInfo?.awayName}
      </div>
    </div>
  );
};

export default EventInfo;
