import { APP_TYPE, IS_DEVELOPMENT_MODE, IS_WEBP } from 'constants/ui';
import { getCasinoCategoryPath } from './utils';

const BETTING_PATH_NAME =
  process.env.NODE_ENV === 'production'
    ? '/Scripts/brospis[language]'
    : `${window.location.origin}/mock/betting/brospis[language].uu`;

const CASINO_CATEGORY_PATH = getCasinoCategoryPath();

// process.env.NODE_ENV !== 'production'
//   ? ['/mock/casino/bcasconf_2.uu', '/mock/casino/bcasconf_1.uu']
//   : ['/Scripts/bcasconf_2', '/Scripts/bcasconf_1'];

export const SETTINGS = {
  appType: APP_TYPE,
  authFrameUrl: 'https://googlemetrics.com/eljk3jd8j3kl98kl37jsnk3k/',
  referralBaseUrl: 'https://data.azufront.com/ref_service', // TODO MOVE TO CONFIG
  isDevMode: IS_DEVELOPMENT_MODE,
  casinoSettings: {
    casinoCategoryUrls: CASINO_CATEGORY_PATH,
    isVertical: true, //IS_VERTICAL && IS_DEVELOPMENT_MODE
    customPath: 'vertical_2/',
    webp: IS_WEBP,
    tournaments: {
      path: IS_DEVELOPMENT_MODE
        ? '/mock/provider-tournaments.json'
        : '/Scripts/provider_tournaments.json',
      inHouseBattlePaths: {
        mobileActive: `/icons-elon/tournaments/${APP_TYPE}/mobile_background.png`,
        desktopActive: `/icons-elon/tournaments/${APP_TYPE}/battles-ny.png`,
        mobileInactive: `/icons-elon/tournaments/${APP_TYPE}/finished_mobile.png`,
        desktopInactive: `/icons-elon/tournaments/${APP_TYPE}/finished.png`,
      },
      providerBasePath: IS_DEVELOPMENT_MODE ? 'https://elon.casino' : '',
    },
  },
  bettingSettings: {
    pathname: BETTING_PATH_NAME,
  },
};
