import AmountInput from 'components/ui/Input/AmountInput';
import {
  PaymentChannelType,
  RublesMethods,
} from 'react-memory-optimization/dist/lib/store/user/types';
import React, { useEffect, useState } from 'react';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';

import Input from 'components/ui/Input/Base';
import {
  extractNumbersFromString,
  valdiationCardNumber,
  validateLuhnAlgorithm,
} from 'utils/validation';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { Steps } from 'stores/money/payments/types';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';

import styles from '../../Withdraw.module.css';
import baseStyles from './RublesDefault.module.css';

type Props = {
  min: number;
  max: number;
  channelId: RublesMethods;
};

const RublesDefault = (props: Props) => {
  const { min, max, channelId } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState('');

  const [cardNumber, setCardNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const handleChangeCardNumber = (value: string) => {
    const validFn =
      channelId === PaymentChannelType.WithdrawRubSber ||
      channelId === PaymentChannelType.WithdrawRubTinkof
        ? valdiationCardNumber
        : validateLuhnAlgorithm;

    const isValid = validFn(
      value,
      (channelId as PaymentChannelType.WithdrawRubSber) ||
        PaymentChannelType.WithdrawRubTinkof
    );

    const errorText = isValid ? '' : 'errors.invalidDate';

    setCardNumber({ value, errorText });
  };

  useEffect(() => {
    if (amount && cardNumber.value && currencyId && !cardNumber.errorText) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          cardNumber: cardNumber.value,
          channelId,
          currencyId,
          requestId: ERequestIds.WithdrawRubles,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, cardNumber, channelId, currencyId]);

  return (
    <div className={baseStyles.wrapper}>
      <AmountInput
        isWithdraw
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      <Input
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.bankAccount"
        size="full"
        patternFn={extractNumbersFromString}
        errorText={cardNumber.errorText}
        onChange={handleChangeCardNumber}
        classes={styles.searchInput}
      />
    </div>
  );
};

export default RublesDefault;
