import { BonusCardLayout, useTranslate } from 'react-gldn-kit';
import { useTransportControllers } from 'react-render-hooks';
import { BonusCardLayoutProps } from 'react-gldn-kit/dist/lib/components/Layouts/BonusCardLayout/types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';
import { getCountDownTranslates } from 'utils/shared';
import { useMemo } from 'react';

type Props = Omit<
  BonusCardLayoutProps & {
    id: number;
  },
  'handleClaim' | 'buttonText' | 'counterProps'
> & {
  withConfirmation?: boolean;
};

const BonusCardComponentKit = (props: Props) => {
  const {
    bonusImagePath,
    id,
    title,
    description,
    withConfirmation = false,
  } = props;

  const names = useMemo(() => getCountDownTranslates(), []);

  const { getText, name } = useTranslate(title, '');

  const { sendCommand } = useTransportControllers();

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const handleClaim = () => {
    if (withConfirmation) {
      closeAndOpen(ModalTypes.ClaimConfirmation, {
        previousModalType: ModalTypes.Bonuses,
        id,
        titleKey: 'popUp.claimBonus.title',
        bodyKey: 'popUp.claimBonus.rakeback',
        buttonKey: 'popUp.claimBonus.claim',
      });

      return;
    }

    sendCommand(
      '23x',
      {
        operationId: id,
        requestId: ERequestIds.CancelOperation,
      },
      true
    );
  };

  return (
    <BonusCardLayout
      {...props}
      title={name}
      description={getText(description)}
      handleClaim={handleClaim}
      buttonText={getText('vipClub.rakeback.claim')}
      counterProps={{ names }}
      bonusImagePath={bonusImagePath}
    />
  );
};

export default BonusCardComponentKit;
