import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import {
  prepareDataForAzn,
  prepareDataForBDTDeposit,
  prepareDataForCripto,
  prepareDataForGLDNBKash,
  prepareDataForMPay,
  prepareDataForMonetics,
  prepareDataForPix,
  prepareInrData,
  prepareInrOctoPayData,
  preparePrkMoneticsData,
  prepareRubleData,
  prepareTurkishData,
  prepareVivaraData,
  prepareWellexPayData,
} from 'utils/transport/depositAdapter';

const getDepositKey = (key: PaymentChannelType) => {
  switch (key) {
    case PaymentChannelType.Pix:
      return '12x';
    case PaymentChannelType.OctopayRocket:
    case PaymentChannelType.OctopayNagad:
    case PaymentChannelType.OctopayUPay:
    case PaymentChannelType.OctopayBKash:
      return '44x';
    case PaymentChannelType.TurkishCepBank:
    case PaymentChannelType.TurkishPapara:
    case PaymentChannelType.VipHavale:
    case PaymentChannelType.TurkishMefete:
    case PaymentChannelType.TurkishExpressHavale:
    case PaymentChannelType.TurkishCreditDebit:
      return '51x';
    case PaymentChannelType.AnindaHavale:
      return '52x';
    case PaymentChannelType.TurkishPayFix:
      return '53x';
    case PaymentChannelType.HavaleHera:
      return '54x';

    default:
      return '40x';
  }
};

export const getDepositInfo = (activeChannelId: PaymentChannelType) => {
  switch (activeChannelId) {
    case PaymentChannelType.BKashFast:
    case PaymentChannelType.ApayBkash:
    case PaymentChannelType.Rocket:
    case PaymentChannelType.BKash:
    case PaymentChannelType.NagadApi:
    case PaymentChannelType.ApayNagad:
    case PaymentChannelType.UPay:
    case PaymentChannelType.Nagad:
    case PaymentChannelType.EasypaisaFast:
    case PaymentChannelType.Easypaisa:
    case PaymentChannelType.JazzCash:
      //  TEST
      return prepareDataForBDTDeposit(activeChannelId);
    case PaymentChannelType.GLDNBKash:
    case PaymentChannelType.PlixoBKash:
      return prepareDataForGLDNBKash();

    case PaymentChannelType.CashXAZN:
      return prepareDataForAzn();

    case PaymentChannelType.Pix:
    case PaymentChannelType.PixPagsmile:
    case PaymentChannelType.PagsmileWalletPicPay:
    case PaymentChannelType.PagsmileBoleto:
    case PaymentChannelType.PagsmileDepositExpress:
    case PaymentChannelType.OctopayRocket:
    case PaymentChannelType.OctopayNagad:
    case PaymentChannelType.OctopayBKash:
    case PaymentChannelType.OctopayUPay:
    case PaymentChannelType.Lottery:
    case PaymentChannelType.WalletAme:
      return prepareDataForPix(activeChannelId, getDepositKey(activeChannelId));

    case PaymentChannelType.BEP:
    case PaymentChannelType.TRC:
    case PaymentChannelType.ERC:
      return prepareDataForCripto();

    case PaymentChannelType.MPayCC:
    case PaymentChannelType.MPayFix:
    case PaymentChannelType.PaparaMPay:
    case PaymentChannelType.MefeteMpay:
    case PaymentChannelType.MefeteMpayQr:
    case PaymentChannelType.MiniHavaleMpay:
    case PaymentChannelType.EFTMPay:
    case PaymentChannelType.PopyMpay:
    case PaymentChannelType.MPayParazula:
    case PaymentChannelType.MPayPayco:
    case PaymentChannelType.MPayVCC:
      return prepareDataForMPay();
    case PaymentChannelType.BkashMonetics:
    case PaymentChannelType.NagadMonetics:
      return prepareDataForMonetics();

    case PaymentChannelType.TurkishPapara:
    case PaymentChannelType.TurkishCepBank:
    case PaymentChannelType.AnindaHavale:
    case PaymentChannelType.TurkishPayFix:
    case PaymentChannelType.HavaleHera:
    case PaymentChannelType.VipHavale:
    case PaymentChannelType.TurkishMefete:
    case PaymentChannelType.TurkishCreditDebit:
    case PaymentChannelType.TurkishExpressHavale:
      return prepareTurkishData(getDepositKey(activeChannelId));
    case PaymentChannelType.BKashFastWellexPay:
    case PaymentChannelType.NagadFastWellexPay:
    case PaymentChannelType.NagadFastWellexPayCopy:
    case PaymentChannelType.BKashFastWellexPayCopy:
    case PaymentChannelType.NagadWellexPay:
    case PaymentChannelType.WellxpayUpay:
    case PaymentChannelType.BkashWellexPay:
    case PaymentChannelType.WellxpayRocket:
    case PaymentChannelType.NagadCashX:
    case PaymentChannelType.BkashCashX:
    case PaymentChannelType.BkashXoomPay:
    case PaymentChannelType.NagadXoomPay:
    case PaymentChannelType.NagadGldn:
    case PaymentChannelType.PlixoNagad:
      return prepareWellexPayData();

    case PaymentChannelType.CreditCardAcquirning:
    case PaymentChannelType.CreditCard:
    case PaymentChannelType.CreditCardFast:
      return prepareRubleData();
    case PaymentChannelType.VivaroBkash:
    case PaymentChannelType.VivaroNagad:
      return prepareVivaraData();

    case PaymentChannelType.InrDeposit:
      return prepareInrData();
    case PaymentChannelType.INRUpiFast:
      return prepareInrOctoPayData();
    // PKR
    case PaymentChannelType.PKRCashmaal:
    case PaymentChannelType.PKREasypaisa:
    case PaymentChannelType.PKRJazzcash:
      return preparePrkMoneticsData();

    default:
      return null;
  }
};
