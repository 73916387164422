import { useState } from 'react';
import { useNavigate } from 'react-router';
import cln from 'classnames';
import { useAuth } from 'react-render-hooks';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { useHookFabric } from 'hooks/useHookFabric';
import { ArrowSvg, CloseSvg } from 'react-gldn-kit';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import RecommendedGames from 'components/CasinoComponents/CasinoRecommendedGames';
import BalanceBlock from './components/BalanceBlock';

import {
  getCasinoGameUrlSelector,
  setCasinoGameUrlAction,
} from 'stores/casino/games/selectors';
import { useZustandCasinoGamesStore } from 'stores/casino/games/store';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { ERouteNames } from 'Routes/types';
import { ModalTypes } from 'stores/modal/types';

import styles from './DesktopLaunchGamePopUp.module.css';
import { getProviderGamesPath } from 'Routes/utils';

type Props = {
  providerName: string;
  providerId: number;
};

const DesktopLaunchGamePopUp = ({ providerName, providerId }: Props) => {
  const casinoUrl = useZustandCasinoGamesStore(getCasinoGameUrlSelector);

  const setUrl = useZustandCasinoGamesStore(setCasinoGameUrlAction);

  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const [isRecommendedGamesShown, setRecommendedGamesState] =
    useState<boolean>(true);

  const navigate = useNavigate();

  const handleClose = () => {
    setUrl('');
  };

  const toggleRecommendedGamesState = () => {
    setRecommendedGamesState(!isRecommendedGamesShown);
  };

  const depositOrSignIn = () => {
    const type = isAuth ? ModalTypes.MoneyOperations : ModalTypes.SignIn;

    setModal(type);
  };

  const jumpToProviderGames = () => {
    handleClose();

    navigate(
      getProviderGamesPath({
        page: '0',
        scroll: '0',
        providerNames: [providerName],
      })
    );
  };

  return (
    <div className={styles.gameFrameWrapper}>
      <div className={styles.wrapperDesktopGames}>
        <div
          className={cln(styles.additionalInfo, {
            [styles.hideGames]: !isRecommendedGamesShown,
          })}
        >
          {isAuth && <BalanceBlock />}

          <ComponentKitBaseButton
            text={isAuth ? 'drawers.casino.depositBtn' : 'authorization.title'}
            size="--button-full"
            buttonHeight="--button-height-l"
            color="primary"
            handleClick={depositOrSignIn}
            classes={styles.depositBtn}
            centreText
          />

          <RecommendedGames providerId={providerId} />

          <ComponentKitBaseButton
            text="showMoreBtn"
            size="--button-full"
            buttonHeight="--button-height-l"
            color="primary"
            handleClick={jumpToProviderGames}
            classes={styles.openProviderGamesBtn}
            centreText
          />
        </div>

        <CloseSvg
          classes={cln(styles.svg, styles.closeSvg)}
          handleClick={handleClose}
        />

        <div
          className={cln(styles.gameFrame, {
            [styles.fullSize]: !isRecommendedGamesShown,
          })}
        >
          <iframe src={casinoUrl} allow="fullscreen" />

          <ArrowSvg
            classes={cln(styles.svg, styles.arrowToggle, {
              [styles.toggleStateArrowPosition]: !isRecommendedGamesShown,
            })}
            onClick={toggleRecommendedGamesState}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopLaunchGamePopUp;
