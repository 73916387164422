import * as CryptoJS from 'crypto-js';

import { COPY_TO_CLIPBOARD } from 'constants/transport';
import { useZustandNotificationStore } from 'stores/notification/store';
import { ENotificationType } from 'stores/notification/types';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getMobileAppsInfoSelector } from 'stores/configuration/selectors';
import { APP_TYPE } from 'constants/ui';

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getUid = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;

  while (counter < 5) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
};
export const copyToClipboard = (text: string) => async () => {
  try {
    await navigator.clipboard.writeText(text);

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: COPY_TO_CLIPBOARD,
        payload: {
          text,
        },
      })
    );

    useZustandNotificationStore.getState().addNotification({
      uid: `copy_success_e+${new Date().getTime()}`,
      text: 'copied',
      type: ENotificationType.Success,
    });
  } catch (err) {
    console.error('🚀 FAIL TO COPY #1:', err);

    try {
      const textarea = document.createElement('textarea');

      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand('copy');

        if (successful) {
          console.log('Text copied');
        } else {
          console.error('Failed to copy');
        }
      } catch (err) {
        console.error('Failed to copy', err);
      }

      document.body.removeChild(textarea);
      useZustandNotificationStore.getState().addNotification({
        uid: `copy_success_e+${new Date().getTime()}`,
        text: 'copied',
        type: ENotificationType.Success,
      });
    } catch (error) {
      useZustandNotificationStore.getState().addNotification({
        uid: `copy_failure+${new Date().getTime()}`,
        text: 'errors.copyFailure',
        type: ENotificationType.Success,
      });
    }
  }
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const getProviderName = (providerName: string) =>
  // eslint-disable-next-line quotes
  providerName.toLowerCase().replaceAll(' ', '').replaceAll("'", '_');

export const getRandomArbitrary = (min: number, max: number) => {
  const base = Math.random() * (max - min);

  return base + min;
};

const base64url = (source: CryptoJS.lib.WordArray): string => {
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
};

export const createJWT = (payload: object, secret: string): string => {
  const header = {
    typ: 'JWT',
    alg: 'HS256',
  };

  const extendedPayload = payload;
  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);
  const stringifiedData = CryptoJS.enc.Utf8.parse(
    JSON.stringify(extendedPayload)
  );
  const encodedData = base64url(stringifiedData);

  const token = encodedHeader + '.' + encodedData;

  let signature: any = CryptoJS.HmacSHA256(token, secret);

  signature = base64url(signature);

  return `${encodedHeader}.${encodedData}.${signature}`;
};

export const openExternalLink = (link: string, isNewWindow = false) => {
  try {
    const a = document.createElement('a');

    a.href = link;

    if (isNewWindow) {
      a.target = '_blank';
    }
    a.click();
  } catch (err) {
    console.info(err);
  }
};

export const getAppLink = () => {
  const appsInfo = getMobileAppsInfoSelector(
    useZustandWebConfiguration.getState()
  );

  if (!appsInfo?.android) {
    return '';
  }

  return `${window.location.origin}/apps/${APP_TYPE}/${appsInfo?.android}?source=${window.location.origin}`;
};

export const downloadApp = () => {
  const link = getAppLink();

  if (!link) {
    return;
  }
  openExternalLink(link, true);
};

export const getCountDownTranslates = () => ({
  day: 'vipClub.boostContent.day',
  hours: 'vipClub.boostContent.hours',
  min: 'vipClub.boostContent.min',
  seconds: 'vipClub.boostContent.seconds',
  shortDay: 'counter.dd',
  shortHH: 'counter.hh',
  shortMM: 'counter.mm',
  shortSec: 'counter.ss',
});
