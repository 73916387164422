import { CheckBox, useTranslate } from 'react-gldn-kit';

import styles from '../../../SignUp.module.css';

type Props = {
  terms: boolean;
  age: boolean;
  handleOpenDoc?: () => void;
  handleToggleState: (s: 'terms' | 'age') => (d: boolean) => void;
};

const DocsCheckBox = (props: Props) => {
  const { age, terms, handleToggleState, handleOpenDoc } = props;

  const { getText } = useTranslate('', '');

  return (
    <>
      <CheckBox
        value={terms}
        onChange={handleToggleState('terms')}
        text={getText('registrations.termsAndConditions')}
        classes={styles.checkbox}
        classesText={styles.link}
        handleText={handleOpenDoc}
      />

      <CheckBox
        value={age}
        onChange={handleToggleState('age')}
        text={getText('registrations.ageConfirm')}
        classes={styles.checkbox}
      />
    </>
  );
};

export default DocsCheckBox;
