import { useEffect, useState } from 'react';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';

import { isFilledForm } from 'utils/validation';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { Steps } from 'stores/money/payments/types';

import styles from '../Withdraw.module.css';

type Props = {
  channelId: PaymentChannelType.INRWithdrawImp;
  min: number;
  max: number;
};

const InrWithdraw = (props: Props) => {
  const { channelId, min, max } = props;

  const [amount, setAmount] = useState('');

  const [accountNumber, setAccountNumber] = useState('');

  const [holderName, setHolderName] = useState('');

  const [ifs, setIfsCode] = useState('');

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  useEffect(() => {
    const isFilled = isFilledForm([amount, accountNumber, holderName, ifs]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          channelId,
          phone: accountNumber,
          emptyOne: holderName,
          emptyTwo: ifs,
          amount,
          requestId: ERequestIds.INRWithdrawImp,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [amount, accountNumber, holderName, ifs, channelId]);

  return (
    <div>
      <AmountInput
        isWithdraw
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />
      <Input
        placeholder="walletPopUp.forms.accountNumber"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        //  errorText={bankCode.errorText}
        //  onChange={handleChangeInput('bankCode')}
        onChange={setAccountNumber}
      />
      <Input
        placeholder="walletPopUp.forms.accountHolderName"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        //  errorText={bankCode.errorText}
        //  onChange={handleChangeInput('bankCode')}
        onChange={setHolderName}
      />
      <Input
        placeholder="walletPopUp.forms.ifs"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        //  errorText={bankCode.errorText}
        //  onChange={handleChangeInput('bankCode')}
        onChange={setIfsCode}
      />

      {/* <div className={baseStyles.row}>
        <WaitingSvg className={baseStyles.icon} />

        <div className={baseStyles.subTitle}>
          T.Processing an application on the side of the payment system can take
          up to 48 hours
        </div>
      </div> */}
    </div>
  );
};

export default InrWithdraw;

// 2) Вывод:

// Каналы IMPS (140), NEFTS (141), RGTS (142)

// requestOctopaysINRWithdraw(data) { // step 87

//         this.sendindex = 0;
//         this.sendarray = [];
//         this.writeShort(data.currencyId);
//         this.writeByte(data.channelType);
//         this.writeUTF(data.name); //Holder_name так называемый (можете в одну строку запрашивать, можете в 2, уточни у майи как лучше)
//         this.writeUTF(data.surname); // Holder_name так называемый (можете в одну строку запрашивать, можете в 2, уточни у майи как лучше)
//         this.writeUTF(data.bank_code); // IFSC code; The letters must be uppercase;
//         this.writeUTF(data.account_number); // Bank account number for providers IMPS, NEFTS, RTGS
//         this.writeStake(data.amount);
//         this.writeByte(10);//request_id
//         this.sendarray[this.sendindex] = 0;
//         this.webSocket.send("bet_payment_create_withdraw" + this.EncodeBase64_2(this.sendarray));
//     }
