import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';
import PhoneInput from 'components/ui/Input/Phone';

import { useZustandFormsStore } from 'stores/money/forms/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { Steps } from 'stores/money/payments/types';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import depositStyles from '../Deposit.module.css';

type Props = {
  channelId:
    | PaymentChannelType.BKashFast
    | PaymentChannelType.ApayBkash
    | PaymentChannelType.GLDNBKash
    | PaymentChannelType.PlixoBKash;
  limit: { min: number; max: number };
};

const BKashFast = ({ limit, channelId }: Props) => {
  const [phone, setPhone] = useState('');

  const [amount, setAmount] = useState<string>('');

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  useEffect(() => {
    if (phone && amount && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          transaction: phone.slice(3, phone.length),
          amount,
          channelId,
          currencyId,
          requestId: ERequestIds.RequestDeposit,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [phone, amount, currencyId, channelId]);

  return (
    <div>
      <PhoneInput
        countryCodeDefault="BD"
        withConfirm={false}
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        sendKey="09x"
        onChange={setPhone}
        isHidePlaceholder={false}
        defaultValue="+880"
        baseValue="+880"
        placeholder="walletPopUp.forms.phone"
        classesWrapper={depositStyles.marginInputBottom}
      />

      <AmountInput
        placeholder="walletPopUp.forms.amount"
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />
    </div>
  );
};

export default BKashFast;
