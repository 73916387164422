import cln from 'classnames';
import { InputBaseProps } from './types';

import { Text } from 'react-gldn-kit';

import styles from './Input.module.css';
import animation from 'styles/animation.module.css';

type Props = InputBaseProps & {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: () => void;
  handleBlure?: () => void;
  value: string;
  isFocused?: boolean;
};

const BaseInputUi = (props: Omit<Props, 'onChange'>) => {
  const {
    handleFocus,
    startIcon,
    value,
    type,
    placeholder,
    handleChange,
    children,
    isHidePlaceholder,
    inputLabelType,
    errorText,
    isFocused,
    inputStyleType,
    size,
    classes,
    handleBlure,
    inputMode,
    disabled,
  } = props;

  const isBorderColorful = inputStyleType === 'border-colorful';

  const sizeModifier = `inputWrapperSize-${size}`;

  return (
    <div
      className={cln(styles.inputWrapper, styles[sizeModifier], classes, {
        [styles.fistIcon]: startIcon,
        [styles.borderColorfulGreen]: isBorderColorful && isFocused,
        [styles.borderColorfulRed]: errorText,
      })}
    >
      {startIcon}

      <input
        onFocus={handleFocus}
        onBlur={handleBlure}
        inputMode={inputMode}
        className={cln(styles.input, {
          [styles.disabled]: disabled,
        })}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
      />

      {children && <div className={styles.rowChildrenData}>{children}</div>}

      <label
        className={cln(styles.label, {
          [styles.labelRight]: startIcon,
          [styles.hideLabel]: isHidePlaceholder,
          [styles.labelUp]: inputLabelType === 'labelUp',
        })}
      >
        <Text idT={placeholder} />
      </label>

      {errorText && (
        <div className={cln(styles.errorText, animation.animationFadeIn)}>
          <Text idT={errorText} />
        </div>
      )}
    </div>
  );
};

export default BaseInputUi;
