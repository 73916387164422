import cln from 'classnames';
import { useBetSlipBets, useBetSlipControllers } from 'react-render-hooks';

import {
  ReturnBetSlipControllerTypes,
  EBetState,
  ECustomBetUid,
  ExpressBet,
} from 'react-memory-optimization/dist/lib/store/betting/entities/betslip/types';

import BetSlipAmount from 'components/ui/Input/BetSlipAmount';
import ExpressOdd from './components/ExpressOdd';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

import betSlipStyles from '../../BetSlipContent.module.css';
import styles from './Express.module.css';

const Express = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { setStake } =
    useHookFabric<ReturnBetSlipControllerTypes>({
      fn: useBetSlipControllers,
    }) || {};

  const expressBet: any = useHookFabric<ExpressBet>({
    fn: useBetSlipBets,
    customProps: {
      betUid: ECustomBetUid.Express,
    },
  });

  if (!expressBet) {
    return null;
  }

  const isError = expressBet.status === EBetState.Error;

  const isDisabled = expressBet.status === EBetState.EventOrLineNotFound;

  return (
    <div
      className={cln(styles.wrapperExpress, {
        [styles.opacity]: isDisabled,
      })}
    >
      <div className={betSlipStyles.leftSide}>
        <div>
          <div className={styles.title}>T.Express bet</div>
          <div className={styles.betCount}>T.bets {expressBet.betLength}</div>
        </div>
        {/* TODO: make separete module for this logic */}
        <div className={betSlipStyles.oddWrapper}>
          <ExpressOdd />
        </div>
      </div>

      {setStake && (
        <BetSlipAmount
          eventUid="-"
          betUid={ECustomBetUid.Express}
          setStake={setStake}
          isPrematch
          isError={isError}
          lineUid="-"
          outcomeId={-1}
          defaultStake={expressBet.stake}
        />
      )}
    </div>
  );
};

export default Express;
