export interface ZustandPromoCodeStore extends ZustandPromoCodeEntities {
  setPromoCode: (promoCode: string) => void;
  setPromoCodeStatus: (status: EPromoCodeStatus, data?: any) => void;
}

interface ZustandPromoCodeEntities {
  promoCode: string;
  status: EPromoCodeStatus;
}

export enum EPromoCodeStatus {
  Idle,
  Requesting,
  Success,
  RejectedLimit,
  RejectedExpared,
}
