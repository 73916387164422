/* eslint-disable no-console */
import { Suspense, lazy, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import cln from 'classnames';

import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import MoneyOperations from './components/MoneyOperations';
import DrawerModal from './components/Drawer';
import Tournament from './components/Casino/CasinoTournaments/Tournament';
import CasinoTournamentPlayers from './components/Casino/CasinoTournaments/Players';
import TournamentRules from './components/Casino/CasinoTournaments/Rules';
import LostPassword from './components/LostPassword';
import Credentials from './components/Credentials';
import Languages from './components/Languages';
import SetNewPassword from './components/SetNewPassword';
import CasinoFavorites from './components/Casino/CasinoFavorites';
import CasinoProviders from './components/Casino/CasinoProviders';
import CalendarModal from './components/Calendar';
import Bonuses from './components/Bonuses';
import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import InGameTickets from './components/Bettings/InGameTickets';
import ReferralCopyContent from './components/Referrals/ReferralCopyContent';
import ReferralWithdraw from './components/Referrals/ReferralWithdraw';
import ReferralFailure from './components/Referrals/ReferralFailure';
import ReferralSuccess from './components/Referrals/ReferralSuccess';
import PromoCodeActivateModal from './components/PromoCode/PromoCodeActivateModal';
import ClaimConfirmation from './components/ClaimConfirmation';
const PrizeModal = lazy(() => import('./components/PrizeModal'));
const MoneyComingPrize = lazy(() => import('./components/MoneyComingModal'));
const CancelBonus = lazy(() => import('./components/CancelBonus'));

const BonusPack = lazy(() => import('./components/BonusPack'));
const TransactionDetails = lazy(
  () => import('./components/TransactionDetails')
);
const CricketPrize = lazy(() => import('./components/CricketPrize'));

import {
  closeAndOpenModalAction,
  getModalAdditionalInfoSelector,
  getModalTypeSelector,
  getNextModalTypeSelector,
} from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './Modal.module.css';
import animation from 'styles/animation.module.css';

const portal = document.getElementsByTagName('body')[0];

const Modal = () => {
  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const modalType = useZustandModalStore(getModalTypeSelector);

  const oldModalType = useZustandModalStore(getNextModalTypeSelector);

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const additionalSettings = useZustandModalStore(
    getModalAdditionalInfoSelector
  );

  const prevType = useRef<ModalTypes | null>(null);

  const isCloseModal = modalType === ModalTypes.CloseModal && prevType.current;

  const isModal =
    modalType !== ModalTypes.Unknown && modalType !== ModalTypes.CloseModal;

  const handleClose = () => {
    closeAndOpen(ModalTypes.Unknown);
  };

  const getModalComponent = () => {
    const key =
      modalType === ModalTypes.CloseModal ? prevType.current : modalType;

    switch (key) {
      case ModalTypes.SignUp:
        return <SignUp />;
      case ModalTypes.Credentials:
        return <Credentials />;

      case ModalTypes.ReferralCopyContent:
        return <ReferralCopyContent />;
      case ModalTypes.ReferralFailure:
        return <ReferralFailure />;
      case ModalTypes.ReferralSuccess:
        return <ReferralSuccess />;
      case ModalTypes.ReferralWithdraw:
        return <ReferralWithdraw />;

      case ModalTypes.SignIn:
        return <SignIn isModal />;
      case ModalTypes.LostPassword:
        return <LostPassword />;
      case ModalTypes.SetNewPassword:
        return <SetNewPassword />;
      case ModalTypes.MoneyOperations: // NOTICE: custom header ( need to change ?)
        return <MoneyOperations />;
      case ModalTypes.DrawerCasinoModal:
        return <DrawerModal isCasino />;
      case ModalTypes.DrawerBettingModal:
        return <DrawerModal />;
      case ModalTypes.CasinoTournament:
        return <Tournament />;
      case ModalTypes.CasinoTournamentPlayers:
        return <CasinoTournamentPlayers />;
      case ModalTypes.TournamentRules:
        return <TournamentRules />;
      case ModalTypes.Languages:
        return <Languages />;
      case ModalTypes.CasinoFavorite:
        return <CasinoFavorites />;
      case ModalTypes.BonusPack:
        return <BonusPack />;
      case ModalTypes.CasinoProviders:
        return <CasinoProviders />;
      case ModalTypes.Calendar:
        return <CalendarModal />;
      case ModalTypes.TransactionDetails:
        return <TransactionDetails />;
      // NOTICE: need to do it ?
      // case ModalTypes.TransactionFilters:
      //   return <TransactionFilter />;
      case ModalTypes.Bonuses:
        return <Bonuses />;
      case ModalTypes.CancelBonus:
        return <CancelBonus />;
      case ModalTypes.ClaimConfirmation:
        return <ClaimConfirmation />;
      // Notice: remove it
      // case ModalTypes.ShortStories:
      //   return <ShortStories />;

      case ModalTypes.PromoCodeActivate:
        return <PromoCodeActivateModal />;
      // bettings
      case ModalTypes.InGameTickets:
        return <InGameTickets />;

      case ModalTypes.Prize:
        return <PrizeModal />;
      case ModalTypes.MoneyComingPrize:
        return <MoneyComingPrize />;
      case ModalTypes.CricketPrize:
        return <CricketPrize />;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (oldModalType) {
      prevType.current = oldModalType;
    }
  }, [oldModalType]);

  useEffect(() => {
    const isModal = modalType !== ModalTypes.Unknown && !isCloseModal;

    if (isModal) {
      document.body.style.overflow = 'hidden';

      return;
    }

    document.body.style.overflow = 'unset';
  }, [modalType, isCloseModal]);

  useEffect(() => {
    if (
      !oldModalType ||
      modalType === null ||
      modalType === ModalTypes.Unknown
    ) {
      prevType.current = null;
    }
  }, [modalType, oldModalType]);

  // TODO OPTIMIZE
  return portal
    ? ReactDOM.createPortal(
        <>
          <div
            className={cln(styles.wrapperModal, {
              [animation.modalOpenMobileAnimation]: isModal && isMobile,
              [animation.modalCloseMobileAnimation]: isCloseModal && isMobile,
              [styles.wallet]: modalType === ModalTypes.MoneyOperations,

              [animation.modalOpenDesktopAnimation]: isModal && !isMobile,
              [animation.modalCloseDesktopAnimation]: isCloseModal && !isMobile,
            })}
          >
            <Suspense
              fallback={
                <div className="full center">
                  <SkeletLoader />
                </div>
              }
            >
              {getModalComponent()}
            </Suspense>
          </div>

          {(isModal || isCloseModal) && (
            <div
              className={cln(styles.black, {
                [animation.animationFadeIn]: isModal,
                [animation.animationFadeOutModal]: isCloseModal,
              })}
              onClick={additionalSettings?.notClosed ? undefined : handleClose}
            />
          )}
        </>,
        portal
      )
    : null;
};

export default Modal;
