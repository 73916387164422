import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { ZustandRequestStore } from './types';

export const getRequestQueueSelector = (state: ZustandRequestStore) =>
  state.queue;

export const resetRequestingLoadersSelector = (state: ZustandRequestStore) =>
  state.reset;

export const isRequestedCommandSelector =
  (id: ERequestIds) => (state: ZustandRequestStore) =>
    Boolean(getRequestQueueSelector(state)[id]);

//==== ACTIONs

export const addRequestKeyForLoaderAction = (state: ZustandRequestStore) =>
  state.addKey;
