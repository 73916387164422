import { EIconType } from 'react-memory-optimization/dist/lib/service/moneyResponse/types';
import {
  Payment,
  PaymentChannelType,
} from 'react-memory-optimization/dist/lib/store/user/types';

export interface ZustandPaymentsStore extends ZustandPaymentsEntities {
  setActiveTab: (tab: ActiveWalletTab) => void;
  setStep: (step: Steps, redirectUrl?: string | null) => void;
  setMessageInfo: (step: PaymentsMessageInfo) => void;
  setActivePaymentChannel: (paymentChannel: PaymentChannelType | null) => void;
  setActivePaymentInfo: (payment: Payment | null) => void;

  setDepositQrFormInfo: (depInfo: DepositQrInfo | null) => void;
}

interface ZustandPaymentsEntities {
  step: Steps;
  redirectUrl: string | null;
  activeTab: ActiveWalletTab;
  messageInfo: PaymentsMessageInfo;
  activeChannelId: PaymentChannelType | null;
  activePaymentInfo: Payment | null;
  depositQrFormInfo: null | DepositQrInfo;
}

type DepositQrInfo = {
  tagText: string;
  walletText: string;
};

type PaymentsMessageInfo = {
  iconType: EIconType | null;
  text: string;
  isQR?: boolean;
  url?: string;
};

export type ActiveWalletTab = 'deposit' | 'withdraw';

export enum Steps {
  ChooseMethod,
  Amount,
  BdtStepTwo,
  TypingData,
  FilledForm,
  MessageScreen,
  RedirectInFrame,
}
