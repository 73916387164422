import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { useZustandFormsStore } from 'stores/money/forms/store';

export const getWithdrawDefaultInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (
    !formInfo ||
    formInfo.isDeposit ||
    formInfo.form.requestId === ERequestIds.MPayWithdraw
  ) {
    return null;
  }

  const { form } = formInfo;

  const { phone = '' } = form;

  return {
    key: '15x',
    data: {
      ...form,
      phone: phone.slice(3),
      emptyOne: '',
      emptyTwo: '',
      amount: Math.floor(+form.amount),
      // TODO request id is not changed
      requestId: ERequestIds.BKashWithdraw,
    },
  };
};

export const getWithdrawCriptoInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '38x',
    data: {
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};
export const getBankPixInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '16x',
    data: {
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};

export const getOctopayInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (
    !formInfo ||
    formInfo.isDeposit ||
    formInfo.form.requestId === ERequestIds.MPayWithdraw
  ) {
    return null;
  }

  const { phone = '' } = formInfo.form;

  return {
    key: '45_withdraw',
    data: {
      ...formInfo.form,
      phone: phone.slice(3),
      amount: Math.floor(Math.floor(+formInfo.form.amount) * 100),
    },
  };
};
export const getPixPagsmileInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '42x',
    data: {
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};
export const getCoinPayInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '50x',
    data: {
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};
export const getTurkishWithdrawInfo = (key = '55x') => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key,
    data: {
      tcNumber: '',
      phone: '',
      iban: '',
      branchCode: '',
      accountNumber: '',
      bankId: '',
      passportDate: '',
      walletNumber: 'wallet number',
      bankName: 'bank name',
      note: 'Note',
      username: '',
      swiftCode: '',
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};
export const getMoneticsWithdrawInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (
    !formInfo ||
    formInfo.isDeposit ||
    formInfo.form.requestId === ERequestIds.MPayWithdraw
  ) {
    return null;
  }

  const { phone = '' } = formInfo.form;

  return {
    key: '57x',
    data: {
      ...formInfo.form,
      phone: phone.slice(3),
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};
export const getMPayWithdrawInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '59x',
    data: {
      bankId: '',
      accountNumber: '',
      iban: '',
      tcnumber: '',
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};
export const getWellexPayWithdrawInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '66x',
    data: {
      accountNumber: '',
      ...formInfo.form,
      amount: Math.floor(Math.floor(+formInfo.form.amount) * 100),
    },
  };
};
export const getRublesData = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '68x',
    data: {
      accountNumber: '',
      ...formInfo.form,
      amount: Math.floor(+formInfo.form.amount * 100),
    },
  };
};

export const getVivaroData = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  return {
    key: '70x',
    data: {
      ...formInfo.form,
      // @ts-ignore
      accountNumber: formInfo.form.phone,
      amount: Math.floor(Math.floor(+formInfo.form.amount) * 100),
    },
  };
};

export const withdrawInr = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  const { form } = formInfo;

  return {
    key: '15x',
    data: form,
  };
};

export const withdrawInrOctoPay = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  const { form } = formInfo;

  return {
    key: '72x',
    data: { ...form, amount: +form.amount * 100 },
  };
};

export const getWithdrawAznInfo = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (!formInfo || formInfo.isDeposit) {
    return null;
  }

  const { form } = formInfo;

  return {
    key: '78x',
    data: { ...form, amount: +form.amount * 100 },
  };
};
