import { create } from 'zustand';

import { EPromoCodeStatus, ZustandPromoCodeStore } from './types';

export const useZustandPromoCodeStore = create<ZustandPromoCodeStore>(
  (set) => ({
    promoCode: '',
    status: EPromoCodeStatus.Idle,
    setPromoCodeStatus(status) {
      set({ status });
    },

    setPromoCode: (promoCode) => {
      set({ promoCode });
    },
  })
);
