import cln from 'classnames';

import { useCasinoHomeCategories } from 'react-render-hooks';
import {
  ECasinoCategoryIds,
  ResponseHomePageInfo,
} from 'react-memory-optimization/dist/lib/store/casino/entities/categories/types';
import { useTranslate } from 'react-gldn-kit';

import ProviderRow from 'components/CasinoComponents/Providers/ProviderRow';
import CasinoCategoryRow from 'components/CasinoComponents/CasinoCategoryRow';
import LastBigWinnersKit from 'components/ui/KitComponents/LastBigWinnersKit';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './HomeCategories.module.css';

type Props = {
  classes?: string;
};

const HomeCategories = ({ classes }: Props) => {
  const homePageInfo = useHookFabric<ResponseHomePageInfo>({
    fn: useCasinoHomeCategories,
  });

  const { name } = useTranslate('categories.original', '');

  return (
    <div className={cln(styles.wrapperHomeCategoriesRow, classes)}>
      {homePageInfo?.rows.map((info, inx: number) => {
        const provider =
          inx === 2 ? (
            <div>
              <LastBigWinnersKit classes={styles.marginB} />
              <ProviderRow classes={styles.marginB} />
            </div>
          ) : null;

        if ('subCategoryId' in info) {
          return (
            <div key={info.subCategoryId}>
              {provider}

              <CasinoCategoryRow
                categoryId={info.categoryId}
                name={info.name}
                subCategoryId={info.subCategoryId}
              />
            </div>
          );
        }

        return (
          <div key={info.id}>
            {provider}

            <CasinoCategoryRow
              categoryId={info.id}
              name={info.id === ECasinoCategoryIds.Turbo ? name : info.name}
            />
          </div>
        );
      })}
    </div>
  );
};

export default HomeCategories;
