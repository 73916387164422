import { useMemo } from 'react';
import { useBetSlipMaxLimit, useOdd, useBetSlipBets } from 'react-render-hooks';
import { OddSubscriberResponse } from 'react-memory-optimization/dist/lib/store/betting/entities/events/types';
import { ExpressBet } from 'react-memory-optimization/dist/lib/store/betting/entities/betslip/types';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandBetSlipStore } from 'stores/betting/betslip/store';
import { getActiveBetSlipTabSelector } from 'stores/betting/betslip/selectors';

import { EBetSlipTabs } from 'stores/betting/betslip/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './MaxBet.module.css';

type Props = {
  betUid: string;
  lineUid: string;
  eventUid: string;
  errorText?: string;
  isPrematch: boolean;
  outcomeId: number;
  setUiStake: (s: string) => void;
  isExpress?: boolean;
};

const MaxBet = (props: Props) => {
  const {
    lineUid,
    eventUid,
    isPrematch,
    outcomeId,
    betUid,
    setUiStake,
    isExpress,
    errorText = '',
  } = props;

  const activeTab = useZustandBetSlipStore(getActiveBetSlipTabSelector);

  const hookFn = isExpress ? useBetSlipBets : useOdd;

  const hookProps = isExpress
    ? { betUid }
    : {
        lineUid,
        eventUid,
        isPrematch,
        outcomeId,
      };

  const oddInfo = useHookFabric<OddSubscriberResponse | ExpressBet>({
    fn: hookFn,
    customProps: hookProps,
  });

  const odd = oddInfo?.odd || 0;

  // const maxLimit = useHookFabric<any>({
  //   fn: useBetSlipMaxLimit,
  //   customProps: { betUid },
  // });
  const maxLimit = 0;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const max = useMemo(() => {
    if (activeTab === EBetSlipTabs.Win) {
      return maxLimit;
    }

    return Math.floor(maxLimit / (+odd - 1));
  }, [odd, maxLimit, activeTab]);

  const handleSetUiStake = () => {
    // setUiStake(max);
  };

  return (
    <div className={styles.maxSumWrapper} onClick={handleSetUiStake}>
      {errorText ? (
        <span className={styles.errorColor}>{errorText}</span>
      ) : (
        <span>
          T.max:
          {max}
          {currencyName}
        </span>
      )}
    </div>
  );
};

export default MaxBet;
