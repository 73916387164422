import React, { useEffect } from 'react';
import { useTransportControllers } from 'react-render-hooks';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import {
  getTokenForAuthorizationSelector,
  isAuthorizedUserSelector,
} from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { getAuthorizationData } from 'utils/transport/main';
import { useHookFabric } from 'hooks/useHookFabric';

type Props = { children: React.ReactElement };

const HOCAuthorization = ({ children }: Props) => {
  const isAvailableTransport = useZustandConnectionStore(
    isAvailableTransportSelector
  );
  const { sendCommand } = useTransportControllers();

  const isAuthorized = useZustandUserStore(isAuthorizedUserSelector);

  const token = useZustandUserStore(getTokenForAuthorizationSelector);

  useEffect(() => {
    if (!isAvailableTransport || isAuthorized || !token) {
      return;
    }

    sendCommand(
      'x00',
      getAuthorizationData(token, token, ERequestIds.AuthorizeByToken)
    );
  }, [isAvailableTransport, isAuthorized, token]);

  return children;
};

export default HOCAuthorization;
