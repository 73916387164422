import { useState } from 'react';

import { ModalLayout, useTranslate } from 'react-gldn-kit';

import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import CasinoTournament from 'components/CasinoComponents/Battles/CasinoTournament';
import ProviderTournaments from 'components/CasinoComponents/Battles/CasinoProviderTournaments';

import { TournamentTabs } from './types';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './Tournament.module.css';

const ModalCasinoTournament = () => {
  const [activeTab, setActiveTab] = useState<TournamentTabs>(
    TournamentTabs.ActiveTournament
  );

  const { name: title } = useTranslate('tournaments.title', '');

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const handleClose = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        transparent: true,
        title,
      }}
      buttonClose={handleClose}
    >
      <>
        <ComponentKitTabs
          activeValue={activeTab}
          data={[
            {
              label: 'tournaments.tabs.activeTournament',
              value: TournamentTabs.ActiveTournament,
            },
            {
              label: 'tournaments.tabs.providerTournaments',
              value: TournamentTabs.ProviderTournaments,
            },
          ]}
          onChange={setActiveTab}
          classes={styles.tabs}
        />

        {activeTab === TournamentTabs.ActiveTournament ? (
          <CasinoTournament />
        ) : (
          <ProviderTournaments onlyActive />
        )}
      </>
    </ModalLayout>
  );
};

export default ModalCasinoTournament;
