import { useCallback } from 'react';
import { useProviders } from 'react-render-hooks';
import { Provider } from 'react-memory-optimization/dist/lib/store/casino/entities/providers/types';
import { Selector } from 'react-gldn-kit';

import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import {
  getActiveProviderIdSelector,
  setActiveProviderIdAction,
} from 'stores/casino/games/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoProviderSelector.module.css';

type Props = {
  classes?: string;
  subCategoryId?: number;
  categoryId?: number;
};

const CasinoProviderSelector = ({
  classes,
  subCategoryId,
  categoryId,
}: Props) => {
  const activeProviderId = useZustandCasinoGamesStore(
    getActiveProviderIdSelector
  );

  const setActiveProviderId = useZustandCasinoGamesStore(
    setActiveProviderIdAction
  );

  const providers = useHookFabric<(Provider & { gameCounter: number })[]>({
    fn: useProviders,
    customProps: {
      isProviders: true,
      withGameCounters: false,
      categoryId,
      subCategoryId,
      sortFn: useCallback(
        () => (p1: Provider, p2: Provider) => p1.name.localeCompare(p2.name),
        []
      ),
    },
  });

  const handleSetActiveProviderId = (id: number | null) => {
    setActiveProviderId(id);
  };

  return (
    <Selector
      placeholder="filterByProviders"
      activeId={activeProviderId}
      data={providers || []}
      size="full"
      handleClick={handleSetActiveProviderId}
      classes={classes}
      classesItem={styles.itemStyles}
      selectedMainItemClasses={styles.mainItem}
      isAll
    />
  );
};

export default CasinoProviderSelector;
