import { Logo } from 'react-gldn-kit';
import { useAuth } from 'react-render-hooks';

import { useLocation, useNavigate } from 'react-router';

import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';

import { ERouteNames } from 'Routes/types';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { isFrameBettingSelector } from 'stores/configuration/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { APP_TYPE } from 'constants/ui';

const ComponentKitLogo = ({
  classes,
  customIsMobile,
}: {
  classes?: string;
  customIsMobile?: boolean;
}) => {
  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const isFrame = useZustandWebConfiguration(isFrameBettingSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAuth = useAuth({ isConnectedApp });

  const navigate = useNavigate();

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const { pathname } = useLocation();

  const isBetting = pathname.includes('betting');

  const returnToMain = () => {
    const path =
      isBetting && !isFrame ? ERouteNames.BettingHome : ERouteNames.CasinoHome;

    navigate(path);
  };

  const isMobileProps =
    customIsMobile !== undefined ? customIsMobile : isMobile;

  return (
    <Logo
      appType={APP_TYPE}
      isMobile={isAuth ? isMobileProps : false}
      handleLogoClick={returnToMain}
      isFullView={isFullView}
      classes={classes}
    />
  );
};

export default ComponentKitLogo;
