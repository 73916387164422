import React from 'react';
import { ENotificationType } from 'stores/notification/types';

import {
  NotificationErrorSvg,
  NotificationSuccessSvg,
  NotificationWarningSvg,
} from 'react-gldn-kit';

type Props = {
  type: ENotificationType;
};

export const NotificationTypeIcon = ({ type }: Props) => {
  if (type === ENotificationType.Success) {
    return <NotificationSuccessSvg />;
  }

  if (type === ENotificationType.Warning) {
    return <NotificationWarningSvg />;
  }

  if (type === ENotificationType.Error) {
    return <NotificationErrorSvg />;
  }

  return <div>def</div>;
};
