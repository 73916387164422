import { useRef, useState } from 'react';
import { useBettingCategoryIds } from 'react-render-hooks';
import cln from 'classnames';

import CategoryName from 'components/Betting/Names/CategoryName';
import BackHeader from './components/BackHeader';
import CategoryTournamentList from './components/CategoryTournamentList';
import SportName from 'components/Betting/Names/SportName';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CategoryInfo.module.css';

type Props = {
  sportId: number;
  isPrematch: boolean;
  resetSportId: () => void;
};

const CategoryInfoBySport = ({ sportId, isPrematch, resetSportId }: Props) => {
  const categories = useHookFabric<{ id: number }[]>({
    fn: useBettingCategoryIds,
    customProps: {
      isPrematch,
      sportId,
    },
  });

  const [activeCategoryId, setActiveCategoryId] = useState<null | number>(null);

  const wrapperElement = useRef<HTMLDivElement | null>(null);

  const handleSetCategory = (id: number | null) => () => {
    wrapperElement.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    setActiveCategoryId(id);
  };

  return (
    <div
      className={cln(styles.wrapperDrawerCategoryInfo, {
        [styles.disabledScroll]: activeCategoryId,
      })}
      ref={wrapperElement}
    >
      <div className={styles.wrapperTableHeader}>
        <BackHeader
          goBack={resetSportId}
          classes={activeCategoryId ? styles.hideSportInfo : undefined}
        >
          <SportName sportId={sportId} />
        </BackHeader>

        {activeCategoryId && (
          <BackHeader goBack={handleSetCategory(null)}>
            <CategoryName
              categoryId={activeCategoryId}
              classes={styles.categoryName}
            />
          </BackHeader>
        )}
      </div>

      {categories?.map(({ id }) => (
        <div
          key={id}
          className={styles.menuItem}
          onClick={handleSetCategory(id)}
        >
          <div className={styles.icon} />

          <CategoryName categoryId={id} classes={styles.categoryName} />
        </div>
      ))}
      {activeCategoryId && (
        <CategoryTournamentList
          categoryId={activeCategoryId}
          isPrematch={isPrematch}
        />
      )}
    </div>
  );
};

export default CategoryInfoBySport;
