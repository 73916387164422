import { IN_HOUSE_BATTLE_TYPE } from 'constants/ui';
import { ERouteNames } from './types';

export const getProviderBattlePath = (
  id: number | string,
  type = IN_HOUSE_BATTLE_TYPE
) => ERouteNames.Battle.replace(':id', `${id}`).replace(':type', type);

type ProvidersProps = {
  providerNames: string[];
  page: string;
  scroll: string;
};
export const getProviderGamesPath = (data: ProvidersProps) =>
  ERouteNames.CasinoProviders.replaceAll(
    ':providerNames',
    data.providerNames.join(',')
  )
    .replaceAll(':page', data.page)
    .replaceAll(':scroll', data.scroll);
