export enum ERouteNames {
  Home = '/',

  CasinoHome = '/casino',
  CasinoGame = '/casino/games/:gameId',
  CasinoCategories = '/casino/categories/:categoryId/:subCategoryId/:page/:scroll',
  CasinoCategoriesFavorite = '/casino/categories/favorite',
  CasinoTournaments = '/casino/tournaments',
  CasinoProviders = '/casino/providers/:providerNames/:page/:scroll',

  BettingHome = '/betting',
  BettingPrematch = '/betting/sport/:sportId',
  BettingLive = '/betting/live/:sportId',

  BettingEventDetailsPrematch = '/betting/sport/event/:eventUid/:sportId',
  // TODO not implemented
  BettingEventDetailsLive = '/betting/live/event/:eventUid/:sportId',

  // TODO not implemented
  BettingTournament = '/betting/tournament/:tournamentId',

  FrameBetting = '/betting/frame',

  ProfileAccount = '/profile/account',
  ProfileTransactions = '/profile/transactions',
  ProfileGameHistory = '/profile/game-history',
  ProfileHistoryBets = '/profile/history-bets',
  ReferralProgram = '/profile/referral-program',
  // TODO base need for hiding tabs
  ProfileBonusBase = '/profile/bonus-cabinet',
  ProfileBonusCabinet = '/profile/bonus-cabinet/:bonusTab',

  Promotions = '/promotions',
  PromotionsBonusOnDeposit = '/promotions/bonus-on-deposit',
  PromotionsVipClub = '/promotions/vip-club',
  PromotionsRankedSystem = '/promotions/ranked-system',
  FAQ = '/promotions/FAQ',
  Applications = '/promotions/applications',
  PromotionMonthly = '/promotions/bonus-monthly',
  Battles = '/promotions/battles',
  Battle = '/promotions/battles/:id/:type',
  NYPromo = '/promotions/battles/new-year',

  // PromoCode
  PromoCode = '/profile/promo-code',

  // Lottery
  Jackpots = '/jackpots',

  // Utils
  DevLanguages = 'dev/languages',
  // Landings
  LandingAviatrix = '/landing/aviatrix',
  // Not implemented
  LandingSlots = '/landing/slots',
  WheelLanding = '/landing/wheel',
  WheelLandingTwo = '/landing/wheel-2',
  WheelLandingThree = '/landing/wheel-3',
  WheelLandingFourth = '/landing/wheel-4',
  PlayMarket = '/landing/play-market',
  WheelRoulette = '/landing/wheel-roulette',
  WheelLandingWithGirls = '/landing/wheel/girls',
  BonusOnDepositLanding = '/landing/bonusOnDeposit',
  LandingRouletteBlack = '/landing/roulette-black',
  OlympLanding = '/landing/olymp',
  OlympusNewLanding = '/landing/g-o-o-n',
  MoneyComingLanding = '/landing/money-coming',

  Buffalo = '/landing/buffalo',
  SweetBonanza = '/landing/bonanza',
  Olympus = '/landing/olympus',
  OlympusTwo = '/landing/olympus-two',
  GreatRhino = '/landing/rhino',
  Tesla = '/landing/tesla',
  MoneyComing = '/landing/money-coming-2',

  LandingCricket = '/landing/cricket',
  CrazyTime = '/landing/crazy-time',
  LandingMostTrusted = '/landing/most-trusted',
  LandingDownloadApp = '/landing/download-app',
}
