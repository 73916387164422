import { ChangeEvent, useEffect } from 'react';
import { useUserInfo } from 'react-render-hooks';
import { UserInfo } from 'react-memory-optimization/dist/lib/store/user/types';

import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import ComponentKitBaseInputUi from 'components/ui/KitComponents/Inputs/ComponentKitInputUi';

import { DefaultInputType, InputBaseProps } from '../Base/types';

import { validationNameSurname } from 'utils/validation';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './InputUserInfo.module.css';

type Props = Omit<InputBaseProps, 'onChange'> & {
  onChange: React.Dispatch<React.SetStateAction<DefaultInputType>>;
  field: DefaultInputType;
  isIgnoreUserInfo?: boolean;
  userInfoKey: keyof UserInfo;
  validationFn?: (d: string) => { value: string; errorText: string };
};

const InputUserInfo = (props: Props) => {
  const {
    onChange,
    field,
    isIgnoreUserInfo,
    userInfoKey,
    validationFn = validationNameSurname,
    ...inputProps
  } = props;

  const userInfo = useHookFabric<UserInfo>({ fn: useUserInfo });

  const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onChange(validationFn(value));
  };

  useEffect(() => {
    if (!userInfo || isIgnoreUserInfo) {
      return;
    }
    const v = userInfo[userInfoKey];

    if (!v) {
      return;
    }

    onChange(validationFn(`${v}`));
  }, [userInfo]);

  if (!userInfo) {
    return (
      <div className={styles.wrapperLoadingInput}>
        <SkeletLoader />
      </div>
    );
  }

  return (
    <ComponentKitBaseInputUi
      {...inputProps}
      value={field.value}
      errorText={field.errorText}
      handleChange={handleChangeField}
    />
  );
};

export default InputUserInfo;
