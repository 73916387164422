import React, { useMemo } from 'react';
import { CountDown } from 'react-gldn-kit';
import { CountDownProps } from 'react-gldn-kit/dist/lib/components/ui/CountDown/types';
import { getCountDownTranslates } from 'utils/shared';

const CountDownKit = (props: Omit<CountDownProps, 'names'>) => {
  const names = useMemo(() => getCountDownTranslates(), []);

  return <CountDown {...props} names={names} />;
};

export default CountDownKit;
