import { NavLink } from 'react-router-dom';
import cln from 'classnames';

import { Text } from 'react-gldn-kit';

import { ERouteNames } from 'Routes/types';
import { IS_DEVELOPMENT_MODE } from 'constants/ui';

import styles from './ProfileTabs.module.css';

const ProfileTabs = () => (
  <nav className={styles.wrapperProfileTabs}>
    <NavLink
      to={ERouteNames.ProfileAccount}
      className={({ isActive }) =>
        cln(styles.navigationButton, {
          [styles.activeNav]: isActive,
        })
      }
    >
      <div className={styles.text}>
        <Text idT="profile.tabs.myAccount" />
      </div>
    </NavLink>

    <NavLink
      to={ERouteNames.ProfileTransactions}
      className={({ isActive }) =>
        cln(styles.navigationButton, {
          [styles.activeNav]: isActive,
        })
      }
    >
      <div className={styles.text}>
        <Text idT="profile.tabs.transactions" />
      </div>
    </NavLink>

    <NavLink
      to={ERouteNames.ProfileGameHistory}
      className={({ isActive }) =>
        cln(styles.navigationButton, {
          [styles.activeNav]: isActive,
        })
      }
    >
      <div className={styles.text}>
        <Text idT="profile.tabs.gameHistory" />
      </div>
    </NavLink>
    <NavLink
      to={ERouteNames.ReferralProgram}
      className={({ isActive }) =>
        cln(styles.navigationButton, {
          [styles.activeNav]: isActive,
        })
      }
    >
      <div className={styles.text}>
        <Text idT="referral.button" />
      </div>
    </NavLink>

    {IS_DEVELOPMENT_MODE && (
      <NavLink
        to={ERouteNames.ProfileHistoryBets}
        className={({ isActive }) =>
          cln(styles.navigationButton, {
            [styles.activeNav]: isActive,
          })
        }
      >
        <div className={styles.text}>
          <Text idT="T.bet history" />
        </div>
      </NavLink>
    )}
  </nav>
);

export default ProfileTabs;
