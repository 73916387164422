import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import EmailInput from 'components/ui/Input/Email';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import PhoneInput from 'components/ui/Input/Phone';

import { DefaultInputType } from 'components/ui/Input/Base/types';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { Steps } from 'stores/money/payments/types';

import {
  isFilledForm,
  validateEmailValue,
  validationNameSurname,
} from 'utils/validation';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import styles from '../../Withdraw.module.css';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  min: number;
  max: number;
};

const MoneticsNagadWithdraw = (props: Props) => {
  const { min, max } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState<string>('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [sureName, setSurname] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    const isNotFillForm = isFilledForm([amount, name, sureName, email, phone]);

    if (currencyId && isNotFillForm === undefined) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          channelId: PaymentChannelType.NagadMonetics,
          amount,
          firstName: name.value,
          familyName: sureName.value,
          email: email.value,
          phone,
          requestId: ERequestIds.WithdrawMonetics,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId, name, sureName, email, phone]);

  return (
    <div>
      <AmountInput
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.amount"
        isWithdraw
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={styles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={sureName}
        onChange={setSurname}
        classes={styles.marginInputBottom}
      />
      <InputUserInfo
        isIgnoreUserInfo
        size="full"
        userInfoKey="email"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        validationFn={validateEmailValue}
        onChange={setEmail}
        field={email}
        placeholder="walletPopUp.forms.email"
        classes={styles.marginInputBottom}
      />

      <PhoneInput
        countryCodeDefault="BD"
        withConfirm={false}
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        sendKey="09x"
        onChange={setPhone}
        isHidePlaceholder={false}
        defaultValue="+880"
        baseValue="+880"
        placeholder="walletPopUp.forms.phone"
        classesWrapper={styles.marginInputBottom}
      />
    </div>
  );
};

export default MoneticsNagadWithdraw;
