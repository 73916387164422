import React from 'react';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { Text } from 'react-gldn-kit';

import { isRequestedCommandSelector } from 'stores/request/selector';
import { useZustandRequestStore } from 'stores/request/store';

import styles from './HOCRequestLoader.module.css';

type Props = {
  command: string;
  requestId: ERequestIds;
  children: React.ReactElement;
  customLoader?: React.ReactElement;
};

const HOCRequestLoader = (props: Props) => {
  const { requestId, children, customLoader } = props;

  const isRequested = useZustandRequestStore(
    isRequestedCommandSelector(requestId)
  );

  if (isRequested) {
    return (
      customLoader || (
        <div className={styles.loader}>
          <Text idT="loading" />
        </div>
      )
    );
  }

  return children;
};

export default HOCRequestLoader;
