import { useEffect, useState } from 'react';
import * as brazilValidation from 'cpf-cnpj-validator';

import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import PixAccountInput from 'components/ui/Input/PixInput';

import styles from '../Withdraw.module.css';

import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { Steps } from 'stores/money/payments/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import {
  composeValidations,
  isFilledForm,
  isRequired,
  validationBankCode,
  validationNameSurname,
} from 'utils/validation';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  min: number;
  max: number;
};

const BankPixWithdraw = (props: Props) => {
  const { min, max } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [sureName, setSurname] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [cpf, setCpf] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [code, setBankCode] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankBranchCode, setBankBranchCode] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [pixAccount, setPixAccount] = useState('');

  const handleValidateForm =
    (
      inputName: 'name' | 'familyName' | 'cpf' | 'bankBranchCode' | 'bankCode'
    ) =>
    (value: string) => {
      if (inputName === 'name' || inputName === 'familyName') {
        const setFn = inputName === 'familyName' ? setSurname : setName;

        setFn(validationNameSurname(value));

        return;
      }

      if (inputName === 'cpf') {
        const isValid = brazilValidation.cpf.isValid(value);
        const errorText = isValid ? '' : 'errors.invalidValue';

        setCpf({ value, errorText });
      }

      if (inputName === 'bankBranchCode') {
        const errorText = composeValidations([
          isRequired({ value, errorText: 'errors.required' }),
        ]);

        setBankBranchCode({ value, errorText });
      }

      if (inputName === 'bankCode') {
        const { errorText } = validationBankCode(value, 'errors.invalidValue');

        setBankCode({ value, errorText });
      }
    };

  useEffect(() => {
    const isFilled = isFilledForm([
      amount,
      name,
      sureName,
      code,
      bankBranchCode,
      cpf,
      pixAccount,
    ]);

    if (currencyId && isFilled) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          channelId: PaymentChannelType.BankPix,
          amount,
          firstName: name.value,
          lastName: sureName.value,
          bankName: code.value,
          branch: bankBranchCode.value,
          ifscCode: '',
          accountNumber: pixAccount,
          cpf: cpf.value,
          TRC20Address: '',
          requestId: ERequestIds.WithdrawBank,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [
    amount,
    currencyId,
    amount,
    name,
    sureName,
    code,
    bankBranchCode,
    cpf,
    pixAccount,
  ]);

  return (
    <div>
      <AmountInput
        placeholder="walletPopUp.forms.phone"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        isWithdraw
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={styles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={sureName}
        onChange={setSurname}
        classes={styles.marginInputBottom}
      />

      <Input
        placeholder="walletPopUp.forms.bankCode"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={code.errorText}
        onChange={handleValidateForm('bankCode')}
      />

      <Input
        placeholder="walletPopUp.forms.bankBranchCode"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={bankBranchCode.errorText}
        onChange={handleValidateForm('bankBranchCode')}
      />

      <Input
        placeholder="walletPopUp.forms.cpf"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={cpf.errorText}
        onChange={handleValidateForm('cpf')}
      />

      <PixAccountInput
        onChange={setPixAccount}
        classes={styles.marginInputBottom}
      />
    </div>
  );
};

export default BankPixWithdraw;
