import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { LastBigWinners } from 'react-gldn-kit';
import { LastBigWinnerInfo } from 'react-gldn-kit/dist/lib/components/LastBigWinners/components/LastBigWinner/types';
import { ReturnSubscribeCasinoLastWinnersProps } from 'react-memory-optimization/dist/lib/store/casino/entities/lastWinners/types';
import { useLastWinner } from 'react-render-hooks';
import { ECasinoCategoryIds } from 'react-memory-optimization/dist/lib/store/casino/entities/categories/types';

import RowHeader from 'components/CasinoComponents/CasinoCategoryRow/components/RowHeader';

import { ERouteNames } from 'Routes/types';

import { useHookFabric } from 'hooks/useHookFabric';

const LastBigWinnersKit = ({
  classes,
  classesCard,
}: {
  classes?: string;
  classesCard?: string;
}) => {
  const [initialWinners, setInitialWinners] = useState<number>(0);

  const navigate = useNavigate();

  const lastWinners = useHookFabric<ReturnSubscribeCasinoLastWinnersProps[]>({
    fn: useLastWinner,
    customProps: {
      limit: 10,
      type: 'base',
    },
  });

  const handleGetNewWinners = (): LastBigWinnerInfo[] => {
    const games: LastBigWinnerInfo[] = [];

    lastWinners?.map((i) => {
      if (!i?.gameInfo?.imageUrl) {
        return;
      }
      const data = {
        gameImg: `${i?.gameInfo?.imageUrl}`,
        gameName: i?.gameInfo?.name,
        gameId: i?.gameInfo?.id,
        prize: `${i?.prize} $`,
        id: i?.clientId,
      };

      games.push(data);
    });

    return games;
  };

  const handleCardClick = (gameId: number) => () => {
    navigate(ERouteNames.CasinoGame.replace(':gameId', `${gameId}`));
  };

  useEffect(() => {
    if (!lastWinners?.length || initialWinners) {
      return;
    }

    setInitialWinners(lastWinners?.length);
  }, [initialWinners, lastWinners]);

  return (
    <div>
      <RowHeader
        iconId={ECasinoCategoryIds.Wager}
        categoryName="lastWin.title"
      />

      <LastBigWinners
        initialCards={initialWinners}
        handleClick={handleCardClick}
        time={3000}
        classes={classes}
        classesCard={classesCard}
        getCardInfo={handleGetNewWinners}
      />
    </div>
  );
};

export default LastBigWinnersKit;
