import React from 'react';
import cln from 'classnames';

import { Text } from 'react-gldn-kit';

import styles from './DataNotFound.module.css';

type Props = {
  text?: string;
  svg?: React.ReactElement;
  isColumn?: boolean;
  classes?: string;
};

const NotFoundData = (props: Props) => {
  const { text = 'notFound', svg, isColumn = false, classes } = props;

  return (
    <div
      className={cln(styles.wrapperDataNotFound, classes, {
        [styles.column]: isColumn,
      })}
    >
      {svg}
      <Text idT={text} />
    </div>
  );
};

export default NotFoundData;
