import cln from 'classnames';

import { ArrowSvg } from 'react-gldn-kit';

import { getDateByFormat } from 'utils/date';

import styles from './TicketTitle.module.css';

type Props = {
  classes?: string;
  ticketId: number;

  timestamp: number;
  toggleState: (d: boolean) => void;
  isVisible: boolean;

  ticketTitle: 'ordinar' | 'express' | 'system';
  additionalText?: string;
};

const TicketTitle = (props: Props) => {
  const {
    classes,
    toggleState,
    isVisible,
    ticketId,
    timestamp,
    ticketTitle,
    additionalText,
  } = props;

  const handleToggleState = () => {
    toggleState(!isVisible);
  };

  return (
    <div
      className={cln(styles.wrapperTicketTitle, classes, {
        [styles.isHidden]: !isVisible,
      })}
      onClick={handleToggleState}
    >
      <div className={styles.leftSection}>
        <div>№ {ticketId}</div>
        <div className={styles.ticketType}>
          ({ticketTitle} {additionalText})
        </div>
        <div className={styles.ticketTime}>
          {getDateByFormat('DD:MM:YYYY | HH:mm', timestamp)}
        </div>
      </div>
      <ArrowSvg />
    </div>
  );
};

export default TicketTitle;
