import { useParams } from 'react-router-dom';
import { useCasinoGame } from 'react-render-hooks';
import { AdaptedGame } from 'react-memory-optimization/dist/lib/store/casino/entities/games/types';

import CasinoGameHeaderActions from 'components/CasinoComponents/CasinoGameHeaderActions';
import LaunchGame from 'components/CasinoComponents/CasinoLaunchGame';
import RecommendedGames from 'components/CasinoComponents/CasinoRecommendedGames';
import CircularLoader from 'components/ui/Loaders/Circular';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoGame.module.css';

const CasinoGamePage = () => {
  const { gameId = 1 } = useParams();

  const game = useHookFabric<AdaptedGame>({
    fn: useCasinoGame,
    customProps: {
      gameId: +gameId,
    },
  });

  if (!game) {
    return (
      <div className={styles.center}>
        <CircularLoader />
      </div>
    );
  }

  const { providerName, providerId } = game;

  return (
    <>
      <div className={styles.wrapperGameHeader}>
        <CasinoGameHeaderActions
          providerName={providerName}
          providerId={providerId}
          specialLabel={game.specialLabel}
        />
      </div>

      {game && <LaunchGame {...game} />}

      <div className={styles.recommendedWrapper}>
        <RecommendedGames providerId={providerId} />
      </div>
    </>
  );
};

export default CasinoGamePage;
