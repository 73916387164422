import cln from 'classnames';
import { useCasinoGame } from 'react-render-hooks';
import { AdaptedGame } from 'react-memory-optimization/dist/lib/store/casino/entities/games/types';

import CasinoGame from 'components/CasinoComponents/CasinoGame';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './ProviderCasinoGame.module.css';

type Props = {
  gameId: number;
  classes?: string;
};

const ProviderCasinoGame = ({ gameId, classes }: Props) => {
  const game = useHookFabric<AdaptedGame>({
    fn: useCasinoGame,
    customProps: {
      gameId,
    },
  });

  if (!game) {
    return null; //<SkeletLoader classes={styles.loaderWrapper} />;
  }

  return <CasinoGame {...game} classes={cln(styles.gameWrapper, classes)} />;
};

export default ProviderCasinoGame;
