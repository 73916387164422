import { useMemo } from 'react';
import cln from 'classnames';
import { useBetSlipControllers, useBetSlipBets } from 'react-render-hooks';
import {
  EBetState,
  ECustomBetUid,
  ReturnBetSlipControllerTypes,
  SystemBet,
} from 'react-memory-optimization/dist/lib/store/betting/entities/betslip/types';

import { Selector } from 'react-gldn-kit';
import BetSlipAmount from 'components/ui/Input/BetSlipAmount';
import SystemOdd from './components/SystemOdd';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './System.module.css';
import betslipStyles from '../../BetSlipContent.module.css';

const System = () => (
  // const { setStake, setSystemRang } = useHookFabric<ReturnBetSlipControllerTypes>({
  //   fn: useBetSlipControllers,
  // });

  // const systemBet = useHookFabric<SystemBet>({
  //   fn: useBetSlipBets,
  //   customProps: {
  //     betUid: ECustomBetUid.System,
  //   },
  // });

  // const selectorData = useMemo(() => {
  //   if (!systemBet || Array.isArray(systemBet)) {
  //     return [];
  //   }

  //   return new Array(systemBet.betLength - 2).fill(0).map((_, inx) => ({
  //     id: inx + 2,
  //     name: `${inx + 2}/${systemBet.betLength}`,
  //   }));
  // }, [systemBet]);

  // if (!systemBet || Array.isArray(systemBet)) {
  //   return null;
  // }

  // const isDisabled = systemBet.status === EBetState.EventOrLineNotFound;

  <div
    className={cln(styles.wrapperSystem, {
      // [styles.opacity]: isDisabled,
    })}
  >
    <div className={betslipStyles.leftSide}>
      {/* <Selector
        size="small"
        activeId={systemBet.activeSystemRange}
        data={selectorData}
        handleClick={setSystemRang}
        isOpenUp
      /> */}

      {/* TODO: make separete module for this logic */}
      <div className={betslipStyles.oddWrapper}>
        <SystemOdd />
        {/* {systemBet?.odd} */}
      </div>
    </div>
    {/* <BetSlipAmount
      isError={false}
      setStake={setStake}
      betUid={ECustomBetUid.System}
      isPrematch
      eventUid="-"
      lineUid="-"
      outcomeId={-1}
      defaultStake={systemBet?.stake}
    /> */}
  </div>
);

export default System;
