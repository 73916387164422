import {
  ChannelType,
  CommonOperationProperties,
  MixerTransactionAdditionalInfo,
} from 'react-memory-optimization/dist/lib/store/operations/types';

import BonusCardComponentKit from 'components/ui/KitComponents/BonusCardComponentKit';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getBonusCardsSelector } from 'stores/configuration/selectors';

type Props = {
  info: MixerTransactionAdditionalInfo;
  operation: CommonOperationProperties;
  id: number;
};

const BattleCard = (props: Props) => {
  const { info, id, operation } = props;

  const { resultValue } = info;

  const bonusImagePaths = useZustandWebConfiguration(getBonusCardsSelector);

  return (
    <BonusCardComponentKit
      title={
        operation.channelType === ChannelType.TournamentPayoutBonus
          ? 'newBonuses.cards.battlesBonus'
          : 'newBonuses.cards.battlesBonus'
      }
      description="newBonuses.cards.battleDescription"
      id={id}
      value={`${resultValue}`}
      bonusImagePath={bonusImagePaths?.battle || ''}
    />
  );
};

export default BattleCard;
