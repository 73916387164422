import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';

import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { Steps } from 'stores/money/payments/types';

import depositStyles from '../Deposit.module.css';

type Props = {
  channelId:
    | PaymentChannelType.Pix
    // redirect payments
    | PaymentChannelType.PixPagsmile
    | PaymentChannelType.PagsmileWalletPicPay
    | PaymentChannelType.PagsmileBoleto
    | PaymentChannelType.PagsmileDepositExpress
    | PaymentChannelType.Lottery
    | PaymentChannelType.OctopayRocket
    | PaymentChannelType.OctopayNagad
    | PaymentChannelType.OctopayBKash
    | PaymentChannelType.OctopayUPay
    | PaymentChannelType.WalletAme

    // Cash X
    | PaymentChannelType.NagadCashX
    | PaymentChannelType.BkashCashX
    // Vivaro
    | PaymentChannelType.VivaroBkash
    | PaymentChannelType.VivaroNagad

    // Cripto
    | PaymentChannelType.TRC
    | PaymentChannelType.ERC
    | PaymentChannelType.BEP
    // Turkish
    | PaymentChannelType.MPayCC;
  // end redirect deps
  requestId: ERequestIds;
  limit: { min: number; max: number };
};

const DefaultDeposit = ({ channelId, requestId, limit }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  useEffect(() => {
    if (amount && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          channelId,
          currencyId,
          requestId,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId, channelId, requestId]);

  return (
    <AmountInput
      placeholder="walletPopUp.forms.amount"
      defaultValue={amount}
      size="full"
      inputStyleType="border-colorful"
      inputLabelType="labelUp"
      onChange={setAmount}
      min={limit.min}
      max={limit.max}
      classes={depositStyles.amountInputMargin}
      wrapperClasses={depositStyles.marginInputBottom}
    />
  );
};

export default DefaultDeposit;
