import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import EmailInput from 'components/ui/Input/Email';

import { useZustandFormsStore } from 'stores/money/forms/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { Steps } from 'stores/money/payments/types';
import { MoneticPaymentTypes } from 'stores/money/forms/types';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { isEmail, isFilledForm, validateEmailValue } from 'utils/validation';

import depositStyles from '../../Deposit.module.css';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  limit: { min: number; max: number };
  channelId: MoneticPaymentTypes;
};

const MoneticsBkashOrNagad = ({ limit, channelId }: Props) => {
  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [amount, setAmount] = useState<string>('');

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  useEffect(() => {
    const isFilled = isFilledForm([email, familyName, name, amount]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          name: name.value,
          familyName: familyName.value,
          channelId,
          currencyId,
          requestId: ERequestIds.RequestDeposit,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
    }
  }, [email, amount, currencyId, name, familyName, channelId]);

  return (
    <div>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />

      <InputUserInfo
        size="full"
        userInfoKey="email"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        validationFn={validateEmailValue}
        onChange={setEmail}
        field={email}
        placeholder="walletPopUp.forms.email"
        classes={depositStyles.marginInputBottom}
      />

      <InputUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        field={name}
        onChange={setName}
        classes={depositStyles.marginInputBottom}
      />

      <InputUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={depositStyles.marginInputBottom}
      />
    </div>
  );
};

export default MoneticsBkashOrNagad;
