import { HOCOutsideClick } from 'react-gldn-kit';
import styles from './ParticularCashout.module.css';

type Props = {
  toggleParticularCashoutState: (e: any) => void;
  handleSetCashoutValue: (n: number) => () => void;
  outputStake: number;
  maxCashout: number;
};

const ParticularCashout = (props: Props) => {
  const {
    handleSetCashoutValue,
    toggleParticularCashoutState,
    maxCashout,
    outputStake,
  } = props;

  const percent = ((outputStake - 1) * 100) / (maxCashout - 1);

  const handleChange = ({ target }: any) => {
    handleSetCashoutValue(+target.value)();
  };

  return (
    <HOCOutsideClick
      classes={styles.partucularCashOutWrapper}
      handleFunc={toggleParticularCashoutState}
    >
      <div className={styles.particularCashOutTitle}>
        T.Area partucular cashout
      </div>

      <input
        type="range"
        className={styles.progress}
        min={1} // TODO min bet
        max={maxCashout || 0}
        value={outputStake}
        onChange={handleChange}
        step={0.2} // TODO min bet
        style={{
          background: `linear-gradient(to right, rgb(var(--third)) ${percent}%, transparent ${percent}%)`,
        }}
      />

      <div className={styles.presets}>
        <div className={styles.dynamicPresets}>
          {/* <ButtonBase
            text="T.min"
            size="super-small"
            isResized
            type="black"
            handleClick={handleSetCashoutValue(1)} // todo min
            classes={styles.preset}
          /> */}
          {/* <ButtonBase
            text="1/2"
            size="super-small"
            isResized
            type="black"
            handleClick={handleSetCashoutValue(maxCashout / 2)}
            classes={styles.preset}
          /> */}
        </div>

        {/* <ButtonBase
          text="T.All In"
          size="super-small"
          isResized
          type="drawer"
          handleClick={handleSetCashoutValue(maxCashout)}
        /> */}
      </div>
    </HOCOutsideClick>
  );
};

export default ParticularCashout;
