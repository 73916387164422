import { Text } from 'react-gldn-kit';
import { useNavigate } from 'react-router';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';
import { ERouteNames } from 'Routes/types';

import styles from './PromotionBanner.module.css';

const PromotionBanner = () => {
  const navigate = useNavigate();

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const jumpTo = () => {
    closeModal(ModalTypes.Unknown);

    navigate(ERouteNames.NYPromo);
  };

  return (
    <div
      className={styles.wrapperPromotionBanner}
      onClick={jumpTo}
      style={{
        backgroundImage:
          'url(/icons-elon/landings/promotions/buttonBackground.png)',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <img
        src="/icons-elon/landings/promotions/gift.png"
        className={styles.gift}
      />

      <div className={styles.title}>
        <Text idT="bigGive.button" />
      </div>
    </div>
  );
};

export default PromotionBanner;
