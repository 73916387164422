import { DefaultInputType } from 'components/ui/Input/Base/types';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';

export type TEmail = {
  errorText?: string;
  value: string;
};

export const isEmail =
  ({ value, errorText }: TEmail) =>
  () => ({
    value,
    errorText: value.match(
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
      ? ''
      : errorText || 'email address not valid',
  });

export const validateEmailValue = (value: string) => ({
  value,
  errorText: value.match(
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? ''
    : 'errors.invalidDate',
});

export const isFilledForm = (
  data: (
    | (
        | DefaultInputType
        | {
            swiftCode: string;
            iban: string;
            bankId: number | string;
          }
      )
    | string
  )[]
): boolean => {
  const isNotFillForm = data.find((i) => {
    if (typeof i === 'string') {
      return !i;
    }

    if ('swiftCode' in i) {
      return !i.swiftCode || !i.iban || !i.bankId;
    }

    return !i.value || i.errorText;
  });

  return isNotFillForm === undefined;
};

export type TRequired = {
  errorText?: string;
  value: string | number;
};

export const isRequired =
  ({ value, errorText }: TRequired) =>
  () => ({
    value,
    errorText:
      value !== '' && value !== null && value !== undefined
        ? ''
        : errorText || 'field have to be filled',
  });
export type MinMaxProps = {
  errorTexts: [string, string];
  value: string | number;
  min: number;
  max: number;
};

export const isMinMax =
  ({ value, errorTexts, min, max }: MinMaxProps) =>
  (): { value: string; errorText: string } => {
    let errorText = '';

    if (+value < min) {
      errorText = errorTexts[0];
    }

    if (+value > max) {
      errorText = errorTexts[1];
    }

    return {
      value: value.toString(),
      errorText,
    };
  };

export type TPassword = {
  errorText: [string, string];
  value: string;
};
const PASSWORD_MIN = 8;
const PASSWORD_MAX = 25;

export const isPassword =
  ({ value, errorText }: TPassword) =>
  () => {
    const length = value.length;

    return {
      value,
      errorText:
        length < PASSWORD_MIN
          ? errorText?.[0]
          : length > PASSWORD_MAX
          ? errorText?.[1]
          : '',
    };
  };
export const replaceValueByMask = (mask: string) => (value: string) => {
  let i = 0;
  const def = mask.replace(/\D/g, '');
  let val = value.replace(/\D/g, '');

  if (def.length >= val.length) {
    val = def;
  }

  const validValue = mask.replace(/./g, function (a) {
    return /[X\d]/.test(a) && i < val.length
      ? val.charAt(i++)
      : i >= val.length
      ? ''
      : a;
  });

  return validValue;
};

export const extractNumbersFromString = (input: string): string => {
  const numericCharacters = input.replace(/[^\d.]/g, '');

  return numericCharacters;
};

export const upperCase = (v: string) => v.toLocaleUpperCase();

export const prepareStringAsPhoneNumber = (str: string): string => {
  // Remove all non-numeric characters
  str = str.replace(/\D/g, '');

  if (str[0] !== '+') {
    str = '+' + str;
  }

  return str;
};

export const validationPatternByName = (name: string) =>
  name.trim().replace(/\s+/g, ' ');

export const validationNameSurname = (
  value: string
): { value: string; errorText: string } => {
  let errorText = '';

  if (value.length < 2) {
    errorText = 'errors.invalidValue';
  }

  return { value, errorText };
};
export const composeValidations = (fns: any[]) =>
  fns.map((f) => f())?.find((result) => result.errorText)?.errorText;

type TPhoneProps = {
  value: string;
  errorText?: string;
};

export const MIN_PHONE_LENGTH = 10;
const MAX_PHONE_LENGTH = 15;

export const isPhone =
  ({ value, errorText = '' }: TPhoneProps) =>
  () => {
    const newValue = prepareStringAsPhoneNumber(value);

    const length = newValue.length;

    if (newValue.startsWith('+880')) {
      return {
        value,
        // 14 this bangladesh number
        errorText: newValue.length !== 14 ? errorText : '',
      };
    }

    if (length > MIN_PHONE_LENGTH && length < MAX_PHONE_LENGTH) {
      return {
        value,
        errorText: '',
      };
    }

    return {
      value,
      errorText: errorText || '',
    };
  };
export const validationBankCode = (value: string, errorMsg?: string) => {
  let errorText = '';

  const pattern = /^[0-9]{3}$/;

  if (!pattern.test(value)) {
    errorText = errorMsg || 'errors.invalidValue';
  }

  return { value, errorText };
};

export const validationTRC = (value: string, errorText?: string) => {
  let error = '';
  const pattern = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;

  if (!pattern.test(value)) {
    error = errorText || 'errors.invalidValue';
  }

  return { value, errorText: error };
};

export const validateLuhnAlgorithm = (cardNumber: string) => {
  let sum = 0;
  let isEven = false;

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);

    if (isEven) {
      digit *= 2;

      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    isEven = !isEven;
  }

  return sum % 10 === 0;
};

export const valdiationCardNumber = (
  cardNumber: string,
  type:
    | PaymentChannelType.WithdrawRubSber
    | PaymentChannelType.WithdrawRubTinkof
) => {
  const T_BANK = [
    437772, 437773, 521324, 528041, 553691, 220070, 518901, 553420, 538994,
  ];

  const SBERBANK_OF_RUSSIA = [
    220220, 417398, 427427, 427432, 427601, 427602, 427603, 427604, 427605,
    427606, 427607, 427608, 427609, 427610, 427611, 427612, 427613, 427616,
    427618, 427620, 427622, 427626, 427627, 427628, 427629, 427630, 427631,
    427633, 427636, 427637, 427638, 427639, 427640, 427641, 427642, 427644,
    427645, 427646, 427649, 427650, 427651, 427652, 427653, 427654, 427655,
    427656, 427659, 427660, 427661, 427662, 427663, 427664, 427666, 427667,
    427668, 427669, 427670, 427672, 427674, 427675, 427676, 427677, 427682,
    427684, 427686, 427903, 427909, 427931, 427936, 427938, 427940, 427945,
    427946, 427949, 427950, 427952, 427962, 427967, 427969, 427970, 427977,
    481776, 522860, 533669, 546901, 546902, 546903, 546904, 546905, 546906,
    546907, 546910, 546911, 546912, 546913, 546916, 546918, 546920, 546922,
    546926, 546927, 546928, 546929, 546930, 546931, 546933, 546935, 546936,
    546937, 546938, 546939, 546940, 546942, 546943, 546944, 546945, 546946,
    546948, 546949, 546950, 546952, 546954, 546955, 546956, 546959, 546960,
    546962, 546963, 546966, 546967, 546968, 546969, 546970, 546972, 546974,
    546975, 546976, 546977, 547927, 548401, 548406, 548412, 548413, 548416,
    548426, 548428, 548438, 548440, 548443, 548444, 548455, 548462, 557000,
    639002, 427617, 427632, 427643, 427681, 427683, 427685, 427687, 427905,
    427906, 427907, 427908, 427912, 427913, 427918, 427926, 427927, 427928,
    427933, 427937, 427941, 427942, 427953, 427954, 427955, 427956, 427960,
    427961, 427963, 427974, 485463, 533205, 545152, 546908, 546909, 546917,
    546932, 546941, 546947, 546951, 546953, 546961, 546964, 547911, 548403,
    548407, 548411, 548431, 548445, 548448, 548449, 548450, 548452, 548456,
    548460, 548461, 548463, 548469, 548470, 548477, 546925, 427680, 531310,
    548435, 548432, 548430, 220100, 548425, 548420, 548410, 427910, 427904,
    427902, 427901, 548402, 548472, 427999, 548476, 427648, 548468, 548451,
    427417, 548459, 548454, 548447, 548442, 427959, 546998, 427975, 427972,
    427966, 548498, 427635, 427625, 427916, 427930, 427929, 676280, 427922,
    427939, 481779,
  ];

  const bin = parseInt(cardNumber.substring(0, 6));

  const data =
    type === PaymentChannelType.WithdrawRubTinkof ? T_BANK : SBERBANK_OF_RUSSIA;

  const isValid = data.includes(bin);

  if (!isValid) {
    return false;
  }

  return validateLuhnAlgorithm(cardNumber);
};
