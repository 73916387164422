import { useEffect, useRef, useState } from 'react';
import * as brazilValidation from 'cpf-cnpj-validator';
import cln from 'classnames';

import { Text, InfoSvg, CloseSvg } from 'react-gldn-kit';
import Input from '../../KitComponents/Inputs/ComponentKitBaseInput';

import { DefaultInputType } from '../Base/types';
import { DEFAULT_INPUT_VALUE } from '../Base/constant';

import { isEmail, isPhone, prepareStringAsPhoneNumber } from 'utils/validation';
import { EPixSelector } from 'components/Alerts/Modal/components/MoneyOperations/components/Forms/Withdraw/types';

import styles from './PixInput.module.css';

type Props = {
  wrapperInput?: string;
  classes?: string;
  onChange: (text: string) => void;
  // TODO move here
  validationType?: EPixSelector;
};

const PixAccountInput = (props: Props) => {
  const { wrapperInput, classes, onChange, validationType = null } = props;

  const [isTooltip, setToolTip] = useState(false);

  const [pixAccount, setPixAccount] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const validType = useRef<null | EPixSelector>(validationType);

  const toggleTooltipState = () => {
    setToolTip(!isTooltip);
  };

  const cpfValid = (value: string) => {
    const isValid = brazilValidation.cpf.isValid(value);

    if (!isValid) {
      setPixAccount({ value, errorText: 'errors.invalidValue' });
      onChange('');

      return;
    }
    setPixAccount({ value, errorText: '' });
    onChange(value);
  };
  const cnpjValid = (value: string) => {
    const isValid = brazilValidation.cnpj.isValid(value);

    if (!isValid) {
      setPixAccount({ value, errorText: 'errors.invalidValue' });
      onChange('');

      return;
    }
    setPixAccount({ value, errorText: '' });
    onChange(value);
  };

  const emailValidation = (value: string) => {
    const dataForValid = {
      value,
      errorText: 'errors.email',
    };

    const data = isEmail(dataForValid)();

    if (!data.errorText) {
      onChange(value);
    } else {
      onChange('');
    }

    setPixAccount({ value, errorText: data.errorText });
  };

  const phoneValidation = (newV: string) => {
    const newValue = prepareStringAsPhoneNumber(newV);

    const { errorText, value } = isPhone({
      value: newV,
      errorText: 'errors.phone',
    })();

    if (errorText) {
      onChange('');
    }

    if (!errorText) {
      onChange(value);
    }

    setPixAccount({ value: newValue, errorText });
  };

  const validationPixAccount = (value: string) => {
    if (validationType) {
      if (validationType === EPixSelector.Cpf) {
        cpfValid(value);

        return;
      }

      if (validationType === EPixSelector.Cnpj) {
        cnpjValid(value);

        return;
      }

      if (validationType === EPixSelector.Email) {
        emailValidation(value);

        return;
      }

      if (validationType === EPixSelector.Phone) {
        phoneValidation(value);

        return;
      }

      onChange(value);
      setPixAccount({
        value,
        errorText: '',
      });

      return;
    }

    if (value[0] === '+') {
      phoneValidation(value);

      return;
    }

    if (value.includes('@')) {
      emailValidation(value);

      return;
    }

    setPixAccount({ value, errorText: '' });
    onChange(value);
  };

  useEffect(() => {
    if (
      !pixAccount.value ||
      !validationType ||
      validType.current === validationType
    ) {
      return;
    }

    validType.current = validationType;
    validationPixAccount(pixAccount.value);
  }, [validationType, pixAccount]);

  const isPhoneType = validationType === EPixSelector.Phone;

  return (
    <div className={classes}>
      <Input
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.pixAccount"
        size="full"
        errorText={pixAccount.errorText}
        onChange={validationPixAccount}
        hardSetValue={
          isPhoneType && pixAccount.value[0] !== '+' ? '+' : undefined
        }
        patternFn={isPhoneType ? prepareStringAsPhoneNumber : undefined}
        classes={cln(wrapperInput, styles.wrapperPixInput, {
          [styles.marginB]: isTooltip,
        })}
      >
        {isTooltip ? (
          <CloseSvg handleClick={toggleTooltipState} />
        ) : (
          <InfoSvg handleClick={toggleTooltipState} />
        )}
      </Input>

      {isTooltip && (
        <div className={styles.tooltipWrapper}>
          <Text idT="walletPopUp.forms.pixDescription" />
        </div>
      )}
    </div>
  );
};

export default PixAccountInput;
