import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';

type Props = {
  fn: any;
  customProps?: any;
};

export const useHookFabric = <T>(props: Props): T | null => {
  const { fn, customProps } = props;

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  // NOtice: sometimes need to get desktop entity
  const fnProps = {
    isMobile,
    ...customProps,
  };

  return fn(fnProps);
};
