import React from 'react';
import cln from 'classnames';

import { AvatarSvg, ProfileSvg, CasinoCoinSvg } from 'react-gldn-kit';

import { IS_ELON } from 'constants/ui';

import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import styles from './TournamentPlayer.module.css';

type Props = {
  position: number;
  payout: number;
  payoutBonus: number;
  rolled: number;
  id: number;
  isHideInfo?: boolean;
  withoutRolled?: boolean;
  classes?: string;
};

const TournamentPlayer = (props: Props) => {
  const {
    position,
    payoutBonus,
    id,
    rolled,
    isHideInfo = false,
    classes,
  } = props;

  return (
    <div className={cln(styles.wrapperTournamentPlayer, classes)}>
      <div className={styles.wrapperPlayerInfoAndPosition}>
        <div
          className={cln(styles.playerPosition, {
            [styles.first]: position === 1,
            [styles.second]: position === 2,
            [styles.third]: position === 3,
          })}
        >
          {position}
        </div>

        <div className={styles.avatarWrapper}>
          {IS_ELON ? <ProfileSvg classes={styles.avatar} /> : <AvatarSvg />}
        </div>

        <div className={styles.wrapperPlayerInfo}>
          <div className={styles.playerName}>{id}</div>
          {/* <div className={styles.sum}>
            {currencyName} {rolled}
          </div> */}
        </div>
      </div>

      <div className={styles.resultValue}>
        <div className={styles.playerResultSum}>
          {/* {currencyName} {payoutBonus} */}
          {rolled.toLocaleString('de-DE')}
        </div>
        {/* {!isHideInfo && (
          <div className={styles.wrapperCoins}>
            <span>{payoutBonus}</span>
            <CasinoCoinSvg classes={styles.coin} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default TournamentPlayer;
