import { useLocation, useNavigate } from 'react-router-dom';

import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import { AppSwitcher, useTranslate } from 'react-gldn-kit';
import { BIG_APP_BUTTONS } from './constants';
import { useMemo } from 'react';

const ComponentKitAppSwitcher = () => {
  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { getText } = useTranslate('', '');

  const closeDrawer = (t: (typeof BIG_APP_BUTTONS)[0]) => () => {
    navigate(t.to);

    setModal(ModalTypes.Unknown);
  };

  const prepareData = useMemo(
    () =>
      BIG_APP_BUTTONS.map((t) => ({
        ...t,
        text: getText(t.text),
        isActive: pathname.includes(t.type),
      })),
    [pathname, getText]
  );

  return (
    <AppSwitcher
      handleClick={closeDrawer}
      isFullView={isFullView}
      data={prepareData}
    />
  );
};

export default ComponentKitAppSwitcher;
