import React, { useEffect, useState } from 'react';
import cln from 'classnames';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';

import InputUserInfo from 'components/ui/Input/InputUserInfo';
import Checkbox from 'components/ui/Checkbox';
import { Text } from 'react-gldn-kit';
import AmountInput from 'components/ui/Input/AmountInput';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { isFilledForm } from 'utils/validation';
import { Steps } from 'stores/money/payments/types';

import depositStyles from '../../Deposit.module.css';
import styles from './TurkishPayFix.module.css';

type Props = {
  limit: { min: number; max: number };
  activeChannelId:
    | PaymentChannelType.PaparaMPay
    | PaymentChannelType.TurkishPayFix;
};

const TurkishPayFix = ({ limit, activeChannelId }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState<string>('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isAgree, setAgreeState] = useState<boolean>(false);

  const toggleAgreementState = () => {
    setAgreeState(!isAgree);
  };

  useEffect(() => {
    const isFilled = isFilledForm([familyName, name, amount]);

    if (isFilled && currencyId && isAgree) {
      setFormInfo({
        isDeposit: true,
        form: {
          currencyId,
          channelId: activeChannelId,
          amount,
          name: name.value,
          familyName: familyName.value,
          requestId:
            activeChannelId === PaymentChannelType.TurkishPayFix
              ? ERequestIds.RequestDeposit
              : ERequestIds.RequestDeposit,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, name, familyName, isAgree, amount, activeChannelId]);

  return (
    <div className={depositStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />

      <InputUserInfo
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={depositStyles.marginInputBottom}
        field={name}
        onChange={setName}
        userInfoKey="name"
      />
      <InputUserInfo
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={depositStyles.marginInputBottom}
        userInfoKey="surname"
      />

      <div
        className={cln(
          depositStyles.marginInputBottom,
          styles.wrapperTurkishPayFixCheckbox
        )}
      >
        <Checkbox checked={isAgree} toggleState={toggleAgreementState} />

        <div className={styles.checkboxDescriptions}>
          <Text idT="walletPopUp.forms.onlyOurWebSite" />
        </div>
      </div>
    </div>
  );
};

export default TurkishPayFix;
