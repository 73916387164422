import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import CasinoDrawer from 'components/Drawers/CasinoDrawer';
import CasinoRightSide from 'components/CasinoComponents/CasinoRightSide';

import { HOCUiTypes } from 'components/HOCs/HOCUiTypes';

import { useZustandDeviceStore } from 'stores/device/store';
import { isDesktopDeviceSelector } from 'stores/device/selectors';

import styles from './DesktopLayout.module.css';

const DesktopLayout = () => {
  const isDesktop = useZustandDeviceStore(isDesktopDeviceSelector);

  return (
    <HOCUiTypes>
      <div className={styles.wrapperContent}>
        <CasinoDrawer isCloseDrawer={false} />

        <div className={styles.desktopContent}>
          <Header />

          <div className={styles.desktopBody}>
            <Outlet />
          </div>
        </div>

        {/* {isDesktop && <CasinoRightSide />} */}
      </div>
    </HOCUiTypes>
  );
};

export default DesktopLayout;
