import { useState } from 'react';

import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import NotFoundData from 'components/ui/DataNotFound';
import ProviderTournament from '../CasinoProviderTournament';

import styles from './CasinoProviderTournaments.module.css';

type Props = {
  onlyActive?: boolean;
};

const ProviderTournaments = ({ onlyActive }: Props) => {
  const providerTournaments: any = false; //useGetProviderTournaments();

  const [activeInx, setActiveInx] = useState(0);

  const changeTournament = (value: number) => () => {
    if (activeInx === data.length - 1 && value > 0) {
      return;
    }

    if (!activeInx && value < 0) {
      return;
    }

    setActiveInx(activeInx + value);
  };

  if (!providerTournaments) {
    return <SkeletLoader classes={styles.wrapperProviderTournaments} />;
  }

  const data = onlyActive
    ? providerTournaments.activeTournaments
    : [
        ...providerTournaments.activeTournaments,
        ...providerTournaments.finishedTournaments,
      ];

  const isFinished =
    activeInx > providerTournaments.activeTournaments.length - 1;

  return (
    <div className={styles.wrapperProviderTournaments}>
      {data[activeInx] ? (
        <ProviderTournament
          {...data[activeInx]}
          isFinished={isFinished}
          key={activeInx}
          isArrow={data.length > 1}
          handleArrowLeft={changeTournament(-1)}
          handleArrowRight={changeTournament(1)}
          isVerticalView={false}
          sliderInfo={
            <div className={styles.sliderInfoWrapper}>
              {activeInx + 1}/{data.length}
            </div>
          }
        />
      ) : (
        <NotFoundData />
      )}
    </div>
  );
};

export default ProviderTournaments;
