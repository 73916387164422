import React from 'react';

import { Text } from 'react-gldn-kit';

import { getMainBalanceValueSelector } from 'stores/user/balance/selectors';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import styles from './AvailableForWithdraw.module.css';

const AvailableForWithdraw = () => {
  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const balance = useZustandBalanceStore(getMainBalanceValueSelector) || 0;

  return (
    <div className={styles.wrapperAvailableForWithdraw}>
      <div className={styles.availableTitle}>
        <Text idT="walletPopUp.availableForWithdraw" />
      </div>
      <div className={styles.availableBalance}>
        {balance.toFixed(2)} {currencyName}
      </div>
    </div>
  );
};

export default AvailableForWithdraw;
