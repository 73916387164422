import { useEffect, useMemo, useState } from 'react';

import { ArrowSvg } from 'react-gldn-kit';
import Input from '../../KitComponents/Inputs/ComponentKitBaseInput';
import BankListPopUp from './components/BankListPopUp';
import InputTooltip from './components/Tooltip';

import { DEFAULT_INPUT_VALUE } from '../Base/constant';
import { BANK_IDS, TURKISH_BANKS, TURKISH_IBAN_MASK } from './constants';

import { DefaultInputType } from '../Base/types';
import { BankIdInfo, TurkishBanks } from './types';

import { replaceValueByMask } from 'utils/validation';

type Props = {
  onChange: (v: { iban: string; bankId: number; swiftCode: string }) => void;
  classesWrapper?: string;
  classesSelector?: string;
  classesInput?: string;
  classesSwift?: string;
  withSwift?: boolean;
  isMpay?: boolean;
  customBankIds?: TurkishBanks[];
};

const TurkishBankInput = (props: Props) => {
  const {
    classesWrapper,
    classesSelector,
    classesInput,
    classesSwift,
    onChange,
    withSwift = false,
    isMpay,
    customBankIds,
  } = props;

  const [iban, setIban] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [swift, setSwift] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isShowBankList, setShowBankListState] = useState<boolean>(false);

  const bankInfo: (TurkishBanks | BankIdInfo)[] =
    customBankIds || (isMpay ? BANK_IDS : TURKISH_BANKS);

  const [selectedBankId, setSelectedBankId] = useState(bankInfo[0].id);

  const selectedBankInfo = useMemo(
    () => bankInfo.find((i: any) => i.id === selectedBankId),
    [selectedBankId]
  );

  const handleValidateForm =
    (inputName: 'iban' | 'swift') => (value: string) => {
      if (inputName === 'iban') {
        const errorText =
          value.length === TURKISH_IBAN_MASK.length
            ? ''
            : 'errors.invalidValue';

        setIban({ value, errorText });
      }

      if (inputName === 'swift') {
        const errorText = value.length >= 4 ? '' : 'errors.invalidValue';

        setSwift({
          value,
          errorText,
        });
      }
    };

  const toggleBankListState = () => {
    setShowBankListState(!isShowBankList);
  };

  useEffect(() => {
    const isIban = iban.value || !iban.errorText;
    const isSwift = swift.value || !swift.errorText;

    if (isSwift || isIban) {
      onChange({
        iban: iban.value,
        bankId: selectedBankInfo?.id || 0,
        swiftCode: swift.value,
      });

      return;
    }

    onChange({
      iban: '',
      bankId: 0,
      swiftCode: '',
    });
  }, [swift, iban, selectedBankInfo]);

  return (
    <div className={classesWrapper}>
      <BankListPopUp
        activeId={selectedBankId}
        isShow={isShowBankList}
        closeList={toggleBankListState}
        setSelectedBankId={setSelectedBankId}
        banks={bankInfo}
      />

      <div onClick={toggleBankListState}>
        <Input
          hardSetValue={selectedBankInfo?.name || ''}
          inputLabelType="labelUp"
          placeholder="walletPopUp.forms.bank"
          size="full"
          disabled
          onChange={console.info}
          classes={classesSelector}
        >
          <ArrowSvg />
        </Input>
      </div>

      <Input
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.iban"
        size="full"
        patternFn={replaceValueByMask(TURKISH_IBAN_MASK)}
        errorText={iban.errorText}
        onChange={handleValidateForm('iban')}
        classes={classesInput}
        baseValue="TR"
      >
        <InputTooltip
          isError={Boolean(iban.errorText)}
          src={selectedBankInfo?.src || ''}
        />
      </Input>

      {withSwift && (
        <Input
          // key={swift.value}
          inputStyleType="border-colorful"
          inputLabelType="labelUp"
          placeholder="walletPopUp.forms.swift"
          size="full"
          errorText={swift.errorText}
          onChange={handleValidateForm('swift')}
          classes={classesSwift}
          // defaultValue={swift.value}
        />
      )}
    </div>
  );
};

export default TurkishBankInput;
