import { useMemo, useState } from 'react';
import { useTransportControllers } from 'react-render-hooks';
import {
  ERequestIds,
  RecoverySubType,
} from 'react-memory-optimization/dist/lib/binary/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import Input from 'components/ui/Input/Base';
import Timer from 'components/ui/Timer';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';

import { composeValidations, isEmail, isRequired } from 'utils/validation';
import { useHookFabric } from 'hooks/useHookFabric';

import { DefaultInputType } from 'components/ui/Input/Base/types';

import styles from './LostPasswordEmailForm.module.css';

const LostPasswordEmailForm = () => {
  const { sendCommand } = useTransportControllers();

  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [code, setCode] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isRequestedCode, setRequestedCodeStatus] = useState<boolean>(false);

  const isShowTimer = () => {
    handleRequestCode();
    setRequestedCodeStatus(true);
  };

  const handleRequestCode = () => {
    const sendData = {
      registrationType: RecoverySubType.Email,
      appType: process.env.REACT_APP_TYPE || -2,
      value: email.value,
      requestId: ERequestIds.RecoveryPasswordSendCode,
    };

    sendCommand('22x', sendData);
  };

  const isValidForm = useMemo(() => {
    const isEmail = email.value && !email.errorText;
    const isCode = code.value && !code.errorText;

    return isCode && isEmail;
  }, [email, code]);

  const submit = () => {
    sendCommand('31x', {
      sms: code.value,
      requestId: ERequestIds.RecoveryPasswordResponse,
    });
  };

  const handleValidate = (type: 'email' | 'code') => (value: string) => {
    if (type === 'email') {
      const errorText = composeValidations([
        isRequired({ value, errorText: 'errors.required' }),
        isEmail({ value, errorText: 'errors.email' }),
      ]);

      setEmail({ value, errorText });

      return;
    }

    const errorText = composeValidations([
      isRequired({ value, errorText: 'errors.required' }),
    ]);

    setCode({ value, errorText });
  };

  return (
    <div>
      <Input
        placeholder="popUp.recoveryPsw.email"
        size="large"
        disabled={isRequestedCode}
        errorText={email.errorText}
        defaultValue=""
        onChange={handleValidate('email')}
        classes={styles.formInput}
      />

      {isRequestedCode ? (
        <Timer handleRequestCode={handleRequestCode} />
      ) : (
        <ComponentKitBaseButton
          color={!email.errorText ? 'primary' : 'dark'}
          handleClick={isShowTimer}
          disabled={Boolean(email.errorText)}
          size="--button-full"
          buttonHeight="--button-height-l"
          text="getCodeBtn"
          classes={styles.formInput}
          centreText
        />
      )}

      {isRequestedCode && (
        <Input
          placeholder="popUp.recoveryPsw.code"
          size="large"
          disabled={false}
          defaultValue=""
          onChange={handleValidate('code')}
          classes={styles.formInput}
        />
      )}

      {isValidForm && (
        <HOCRequestLoader
          command="\\31x"
          requestId={ERequestIds.RecoveryPasswordResponse}
        >
          <ComponentKitBaseButton
            color="primary"
            size="--button-full"
            buttonHeight="--button-height-l"
            handleClick={submit}
            text="popUp.recoveryPsw.submitBtn"
            centreText
          />
        </HOCRequestLoader>
      )}
    </div>
  );
};

export default LostPasswordEmailForm;
