import React, { useEffect, useState } from 'react';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import withdrawStyles from '../../Withdraw.module.css';
import Input from 'components/ui/Input/Base';
import { isFilledForm, validationNameSurname } from 'utils/validation';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { Steps } from 'stores/money/payments/types';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import AmountInput from 'components/ui/Input/AmountInput';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  min: number;
  max: number;
};

const TurkishPapara = ({ min, max }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [walletNumber, setWalletNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [amount, setAmount] = useState<string>('');

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const handleValidateForm = (inputName: 'walletNumber') => (value: string) => {
    const errorText = value.length < 7 ? 'errors.invalidValue' : '';

    setWalletNumber({ value, errorText });

    return;
  };

  useEffect(() => {
    const isFilled = isFilledForm([name, familyName, amount, walletNumber]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          currencyId,
          walletNumber: walletNumber.value,
          channelId: PaymentChannelType.TurkishPapara,
          firstName: name.value,
          familyName: familyName.value,
          requestId: ERequestIds.WithdrawPapara,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, name, familyName, amount, walletNumber]);

  return (
    <div className={withdrawStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputLabelType="labelUp"
        isWithdraw
        inputStyleType="border-colorful"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        classes={withdrawStyles.amountInputMargin}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />

      <Input
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.walletNumberTurkish"
        inputStyleType="border-colorful"
        size="full"
        errorText={walletNumber.errorText}
        onChange={handleValidateForm('walletNumber')}
        classes={withdrawStyles.marginInputBottom}
      />

      <InputUserInfo
        isIgnoreUserInfo
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        inputStyleType="border-colorful"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
        userInfoKey="name"
      />
      <InputUserInfo
        isIgnoreUserInfo
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        inputStyleType="border-colorful"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setFamilyName}
        field={familyName}
        userInfoKey="surname"
      />
    </div>
  );
};

export default TurkishPapara;
