import cln from 'classnames';

import { DefaultImgSvg } from 'react-gldn-kit';

import styles from './DefaultGameImg.module.css';

type Props = {
  classes?: string;
  gameName: string;
};

const DefaultGameImg = ({ classes, gameName }: Props) => (
  <div className={cln(styles.wrapperDefaultGame, classes)}>
    <DefaultImgSvg classes={styles.img} />

    <div className={styles.gameName}>{gameName}</div>
  </div>
);

export default DefaultGameImg;
