import { useGetCategoryInfo } from 'react-render-hooks';

import { useHookFabric } from 'hooks/useHookFabric';

type Props = { categoryId: number; classes?: string };

// all styles from parents
const CategoryName = ({ classes, categoryId }: Props) => {
  const category: any = useHookFabric<any>({
    fn: useGetCategoryInfo,
    customProps: { categoryId },
  });

  return <div className={classes}>{category?.name}</div>;
};

export default CategoryName;
