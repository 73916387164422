import { useMemo } from 'react';
import { useRakeBack } from 'react-render-hooks';
import { ReturnTypeRakeBake } from 'react-memory-optimization/dist/lib/store/operations/entities/vipClub/types';

import BonusCardComponentKit from 'components/ui/KitComponents/BonusCardComponentKit';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getBonusCardsSelector } from 'stores/configuration/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

const RakeBackCard = () => {
  const bonusImagePaths = useZustandWebConfiguration(getBonusCardsSelector);

  const rakebackInfo = useHookFabric<ReturnTypeRakeBake>({
    fn: useRakeBack,
  });

  const bonusImagePath = rakebackInfo?.isBoost
    ? bonusImagePaths?.rakebackBoost
    : bonusImagePaths?.rakeback;

  const rakeBack = useMemo(() => {
    if (!rakebackInfo?.rakeback) {
      return 0;
    }

    if (rakebackInfo?.rakeback < 0) {
      return 0;
    }

    return rakebackInfo.rakeback;
  }, [rakebackInfo]);

  return (
    <BonusCardComponentKit
      title="newBonuses.cards.rakeback"
      description="newBonuses.cards.rakebackDescription"
      id={rakebackInfo?.id || -1}
      value={`${rakeBack}`}
      endTimestamp={
        !rakebackInfo?.endTime || !rakebackInfo?.isBoost
          ? undefined
          : rakebackInfo?.endTime
      }
      withConfirmation={rakebackInfo?.isBoost}
      bonusImagePath={bonusImagePath || ''}
    />
  );
};

export default RakeBackCard;
