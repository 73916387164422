import cln from 'classnames';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import {
  DrawerButton,
  BaseButton,
  DrawerLayout,
  useTranslate,
  ProfileSvg,
  FavoritesSvg,
  WalletSvg,
  VipClubSvg,
  ProvidersSvg,
  NavTournamentSvg,
  // MobileApp,
  NavReferralSvg,
  DownloadSvg,
} from 'react-gldn-kit';
import { useFavoriteGames, useAuth } from 'react-render-hooks';
import { AdaptedGame } from 'react-memory-optimization/dist/lib/store/casino/entities/games/types';

import PromotionBanner from './components/PromotionBanner';
import AppSwitcher from './components/AppSwitcher';
import CasinoProviderButtons from './components/CasinoProviderButtons';
import BonusButtons from './components/BonusButtons';
import DrawerDocs from './components/DrawerDocs';
import SocialButtons from './components/SocialButtons';
import ButtonLanguage from 'components/ui/Buttons/ButtonLanguage';
import JivoChat from 'components/JivoChat';
import ComponentKitLogo from 'components/ui/KitComponents/ComponentKitLogo';

import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { useHookFabric } from 'hooks/useHookFabric';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import {
  getPromotionPathSelector,
  getSupportMailSelector,
  isAvailableBettingSelector,
} from 'stores/configuration/selectors';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';

import { ModalTypes } from 'stores/modal/types';
import { ERouteNames } from 'Routes/types';

import styles from './CasinoDrawer.module.css';

const CasinoDrawer = ({ isCloseDrawer }: { isCloseDrawer: boolean }) => {
  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const promotionPath = useZustandWebConfiguration(getPromotionPathSelector);

  const drawerWrapper = useRef<HTMLDivElement>(null);

  const mail = useZustandWebConfiguration(getSupportMailSelector);

  const { getText } = useTranslate('', '');

  const games = useHookFabric<AdaptedGame[]>({
    fn: useFavoriteGames,
  });

  const isAvailableBetting = useZustandWebConfiguration(
    isAvailableBettingSelector
  );

  const navigate = useNavigate();

  const jumpTo = (path: string) => () => {
    closeAndOpen(ModalTypes.Unknown);
    navigate(path);
  };

  const openModal =
    (t: ModalTypes, isAuthImportant = false) =>
    () => {
      if (isAuthImportant && !isAuth) {
        closeAndOpen(ModalTypes.SignIn);

        return;
      }
      closeAndOpen(t);
    };

  return (
    <DrawerLayout
      drawerWrapper={drawerWrapper}
      isMobile={isMobile}
      isFullView={isFullView}
      isCloseDrawer={isCloseDrawer}
      classes={cln({
        [styles.short]: !isFullView,
      })}
    >
      <div className={styles.contentDrawerWrapper}>
        {!isMobile && (
          <div className={styles.desktopLogo}>
            <ComponentKitLogo />
          </div>
        )}

        {isAvailableBetting && <AppSwitcher />}

        {isFullView && (
          <BaseButton
            text={getText('drawers.casino.depositBtn')}
            handleClick={openModal(ModalTypes.MoneyOperations, true)}
            buttonHeight="--button-height-xl"
            size="--button-full"
            centreText
            startIcon={<WalletSvg classes={styles.fillAsButtonText} />}
            color="primary"
            classes={styles.marginBottom}
          />
        )}

        {promotionPath && <PromotionBanner />}

        <DrawerButton
          size="full"
          text={getText('profile.tabs.myAccount')}
          handleClick={jumpTo(ERouteNames.ProfileAccount)}
          startIcon={<ProfileSvg className={styles.green} />}
          classes={styles.marginBottom}
          isFullView={isFullView}
        />

        <DrawerButton
          size="full"
          text={getText('favorites')}
          handleClick={openModal(ModalTypes.CasinoFavorite)}
          startIcon={
            <FavoritesSvg
              classes={cln(styles.stroke, {
                [styles.hasFavItems]: games?.length,
              })}
            />
          }
          classes={styles.marginBottom}
          isFullView={isFullView}
        >
          {games?.length ? (
            <div className={styles.favItems}>{games?.length}</div>
          ) : undefined}
        </DrawerButton>

        <div className={styles.marginBottom}>
          <BonusButtons />
        </div>

        <DrawerButton
          size="full"
          text={getText('vipClub.vipClub')}
          handleClick={jumpTo(ERouteNames.PromotionsVipClub)}
          startIcon={<VipClubSvg classes={styles.vipClubSvg} />}
          classes={styles.marginBottom}
          isFullView={isFullView}
        />

        <div className={styles.marginBottom}>
          <CasinoProviderButtons />
        </div>

        <DrawerButton
          size="full"
          text={getText('offers')}
          handleClick={jumpTo(ERouteNames.Promotions)}
          startIcon={<ProvidersSvg classes={styles.green} />}
          classes={styles.marginBottom}
          isFullView={isFullView}
        />

        <DrawerButton
          size="full"
          text={getText('battles')}
          handleClick={jumpTo(ERouteNames.Battles)}
          startIcon={<NavTournamentSvg classes={styles.green} />}
          classes={styles.marginBottom}
          isFullView={isFullView}
        />

        <DrawerButton
          size="full"
          text={getText('mobileApp')}
          handleClick={jumpTo(ERouteNames.Applications)}
          startIcon={<DownloadSvg className={styles.green} />}
          classes={styles.marginBottom}
          isFullView={isFullView}
        />

        <div className={cln(styles.marginBottom, styles.dash)} />

        <div className={styles.bigMargin}>
          <DrawerButton
            size="full"
            text={getText('referral.button')}
            handleClick={jumpTo(ERouteNames.ReferralProgram)}
            startIcon={<NavReferralSvg classes={styles.green} />}
            classes={styles.marginBottom}
            isFullView={isFullView}
          />

          <DrawerDocs />
        </div>
      </div>

      <div className={styles.desktopBottomWrapper}>
        <div className={styles.bigMargin}>
          <SocialButtons />
        </div>

        <div className={styles.wrapperLangAndSupport}>
          <div className={styles.buttonLang}>
            <ButtonLanguage />
          </div>

          <div
            className={cln(styles.rowSupport, {
              [styles.column]: !isFullView,
            })}
          >
            <div className={styles.jivoBtn}>
              <JivoChat isDisableText />
            </div>

            <a className={styles.email} href={`mailto:${mail}`}>
              <span className={styles.left}>{mail}</span>
            </a>
          </div>
        </div>
      </div>
    </DrawerLayout>
  );
};

export default CasinoDrawer;
