import { useEffect, useMemo, useState } from 'react';
import cln from 'classnames';
import 'react-phone-number-input/style.css';

import PhoneInputLib, { isValidPhoneNumber } from 'react-phone-number-input';
import { useTransportControllers, useCountryInfo } from 'react-render-hooks';
import {
  ERequestIds,
  RecoverySubType,
} from 'react-memory-optimization/dist/lib/binary/types';
import { CountryInfo } from 'react-memory-optimization/dist/lib/store/user/types';

import BaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import Timer from 'components/ui/Timer';
import { Text, useTranslate } from 'react-gldn-kit';

import { COUNTRY_MOCK } from 'components/Alerts/Modal/components/SignUp/components/CountrySelector/constants';
import { DEFAULT_INPUT_VALUE } from '../Base/constant';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { DefaultInputType, InputBaseProps } from '../Base/types';

import {
  composeValidations,
  isPhone,
  isRequired,
  prepareStringAsPhoneNumber,
} from 'utils/validation';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './Phone.module.css';

// TODO move to lib

type Props = InputBaseProps &
  (PhoneInputPropsWithConfirmation | PhoneInputPropsWithoutConfirmation) & {
    countryCodeDefault?: string;
  };

export type PhoneInputPropsWithoutConfirmation = {
  withConfirm: false | undefined;
  requestId?: ERequestIds;
  sendDataType?: RecoverySubType | RecoverySubType;
  classesWrapper?: string;
  requestCallback?: () => void;
  sendKey?: string;
  section?: undefined;
};
export type PhoneInputPropsWithConfirmation = {
  withConfirm: true;
  section: 1 | 2 | 3; // 1 registration 2 assign phone 3 - recovery
  requestId: ERequestIds;
  sendDataType: RecoverySubType | RecoverySubType;
  classesWrapper?: string;
  requestCallback?: () => void;
  sendKey?: string;
};

const PhoneInput = (props: Props) => {
  const {
    withConfirm,
    requestId,
    sendDataType,
    children,
    classesWrapper,
    requestCallback,
    onChange,
    sendKey,
    countryCodeDefault,
    section = undefined,
  } = props;

  const [phone, setPhone] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isRequestedCode, setRequestedCodeStatus] = useState<boolean>(false);

  const { sendCommand } = useTransportControllers();

  const { getText } = useTranslate('', '');

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const countryInfo = useHookFabric<CountryInfo>({
    fn: useCountryInfo,
  });

  const defaultCountry = useMemo(() => {
    const country =
      countryCodeDefault ||
      COUNTRY_MOCK.find(
        (c) => c.id === countryInfo?.countryId
      )?.imageCode?.toLocaleUpperCase();

    return country;
  }, [countryInfo, countryCodeDefault]);

  const isValidPhone = useMemo(() => {
    const isValid = phone.value.length > 2 && !phone.errorText;

    return isValid;
  }, [phone]);

  const handleRequestCode = () => {
    if (!withConfirm) {
      return;
    }
    const baseData = {
      registrationType: sendDataType,
      appType: process.env.REACT_APP_TYPE || 0,
      value: prepareStringAsPhoneNumber(phone.value),
      requestId,
      section,
    };

    const baseKey = sendKey || '22x';

    sendCommand(baseKey, baseData);
  };

  const handleSetValue = (value = '') => {
    let errorText = isValidPhoneNumber(value) ? '' : 'errors.phone';

    if (countryCodeDefault === 'BD') {
      const isValidRegionCode = value.startsWith('+8801');

      if (!isValidRegionCode) {
        errorText = 'errors.phone';
      }
    }

    if (countryCodeDefault === 'IN') {
      errorText = value.length !== 13 ? 'errors.phone' : '';
    }

    setPhone({ value, errorText });
  };

  const showTimer = () => {
    handleRequestCode();
    setRequestedCodeStatus(true);
    requestCallback?.();
  };

  useEffect(() => {
    if (phone.value && !phone.errorText) {
      const errorText = composeValidations([
        isRequired({ value: phone.value, errorText: 'errors.required' }),
        isPhone({ value: phone.value, errorText: 'errors.phone' }),
      ]);
      // TODO first render

      if (errorText) {
        return;
      }

      onChange(phone.value);

      return;
    }

    onChange('');
  }, [phone, onChange]);

  return (
    <div className={cln(styles.wrapper, classesWrapper)}>
      <PhoneInputLib
        international
        countryCallingCodeEditable={false}
        isvalid={phone.errorText}
        withCountryCallingCode={countryCodeDefault}
        limitMaxLength
        // CountryCode
        // @ts-ignore
        defaultCountry={defaultCountry}
        // @ts-ignore
        countries={countryCodeDefault ? [countryCodeDefault] : undefined}
        disabled={isRequestedCode}
        onChange={handleSetValue}
        className={cln(styles.input, {
          [styles.errorBorder]: phone.errorText,
        })}
      >
        {children}
      </PhoneInputLib>

      {phone.errorText && (
        <div className={styles.error}>
          <Text idT={phone.errorText} />
        </div>
      )}

      {withConfirm && (
        <>
          {isRequestedCode ? (
            <Timer handleRequestCode={handleRequestCode} />
          ) : (
            <BaseButton
              text={getText('getCodeBtn')}
              disabled={!isValidPhone}
              color="primary"
              handleClick={showTimer}
              size="--button-full"
              centreText
              buttonHeight="--button-height-full"
              classes={styles.marginB}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PhoneInput;
