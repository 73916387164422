import { Text } from 'react-gldn-kit';

import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import styles from './ProgressWagering.module.css';

type Props = {
  fullRolled: number;
  rolled: number;
  percent: number;
  wager: number;
  classes?: string;
};

const ProgressWagering = (props: Props) => {
  const { fullRolled, percent, classes, wager } = props;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  return (
    <div className={classes}>
      <div className={styles.titleRow}>
        <div className={styles.row}>
          <div className={styles.wagerText}>
            <Text idT="wagering.title" specValue={`(${wager}x)`} />
          </div>
          <div>
            {fullRolled} {currencyName}
          </div>
        </div>
        <div className={styles.maxValue}>
          {percent.toFixed(2)}%
          {/* <Text idT="wagering.max" specValue={`${fullRolled}`} /> */}
        </div>
      </div>
      <div className={styles.wrapperProgressWagering}>
        <div className={styles.progress} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
};

export default ProgressWagering;
