import { useEffect, useMemo, useState } from 'react';

import * as brazilValidation from 'cpf-cnpj-validator';

import AmountInput from 'components/ui/Input/AmountInput';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { Selector } from 'react-gldn-kit';
import Input from 'components/ui/Input/Base';

import PixAccountInput from 'components/ui/Input/PixInput';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';

import { isFilledForm } from 'utils/validation';

import { FIRST_SELECTOR, SECOND_SELECTOR } from './constants';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { EPixSelector } from '../types';

import styles from '../Withdraw.module.css';

type Props = {
  min: number;
  max: number;
};

const PixPagsmileWithdraw = (props: Props) => {
  const { min, max } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [activeFirstSelectorValue, setFirstSelectorValue] = useState(
    EPixSelector.Cpf
  );

  const [activeSecondSelectorValue, setSecondSelectorValue] = useState(
    EPixSelector.Cpf
  );

  const [amount, setAmount] = useState('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [revalidatePixAccount, setRevalidatePixAccount] =
    useState<boolean>(false);

  const [sureName, setSurname] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [document, setDocumentId] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [pixAccount, setPixAccount] = useState<string>('');
  // useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const disabledValidation = useMemo(
    () =>
      [EPixSelector.Cnpj, EPixSelector.Evp].includes(activeSecondSelectorValue),
    [activeSecondSelectorValue]
  );

  const changeSelector = (name: 'first' | 'second') => (v: EPixSelector) => {
    if (name === 'first') {
      setFirstSelectorValue(v);

      const validateFn =
        v === EPixSelector.Cpf ? brazilValidation.cpf : brazilValidation.cnpj;

      const { value } = document;

      const isValid = validateFn.isValid(value);

      if (!isValid) {
        setDocumentId({
          value,
          errorText: 'errors.invalidValue',
        });

        return;
      }
      setDocumentId({
        value,
        errorText: '',
      });

      return;
    }

    setRevalidatePixAccount(true);
  };

  const handleChangeInput =
    (type: 'document' | 'pixAccount') => (value: string) => {
      if (type === 'pixAccount') {
        setRevalidatePixAccount(false);
        setPixAccount(value);
      }

      if (type === 'document') {
        const validateFn =
          activeFirstSelectorValue === EPixSelector.Cpf
            ? brazilValidation.cpf
            : brazilValidation.cnpj;

        const isValid = validateFn.isValid(value);

        if (!isValid) {
          setDocumentId({
            value,
            errorText: 'errors.invalidValue',
          });

          return;
        }
        setDocumentId({
          value,
          errorText: '',
        });
      }
    };

  useEffect(() => {
    const isFillForm = isFilledForm([
      amount,
      name,
      sureName,
      document,
      pixAccount,
    ]);

    if (currencyId && isFillForm) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId: currencyId,
          channelId: PaymentChannelType.PixPagsmile,
          name: name.value,
          documentId: document.value,
          documentType:
            `${EPixSelector[activeFirstSelectorValue]}`.toUpperCase(),
          account: pixAccount,
          accountType:
            `${EPixSelector[activeSecondSelectorValue]}`.toUpperCase(),
          bankCode: '',
          branch: '',
          accountDigit: '',
          branchDigit: '',
          amount,
          requestId: ERequestIds.PixPagsmileWithdraw,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [
    activeFirstSelectorValue,
    activeSecondSelectorValue,
    amount,
    name,
    sureName,
    document,
    pixAccount,
  ]);

  return (
    <div className={styles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        isWithdraw
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />
      <InputUserInfo
        userInfoKey="name"
        isIgnoreUserInfo
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={styles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        userInfoKey="surname"
        isIgnoreUserInfo
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={sureName}
        onChange={setSurname}
        classes={styles.marginInputBottom}
      />

      <Selector
        placeholder=""
        activeId={activeFirstSelectorValue}
        handleClick={changeSelector('first')}
        data={FIRST_SELECTOR}
        classes={styles.marginInputBottom}
        size="full"
      />

      <Input
        placeholder="walletPopUp.forms.document"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={document.errorText}
        onChange={handleChangeInput('document')}
      />

      <Selector
        placeholder=""
        activeId={activeSecondSelectorValue}
        handleClick={setSecondSelectorValue}
        data={SECOND_SELECTOR}
        isOpenUp
        classes={styles.marginInputBottom}
        size="full"
      />
      <PixAccountInput
        onChange={handleChangeInput('pixAccount')}
        validationType={activeSecondSelectorValue}
      />
    </div>
  );
};

export default PixPagsmileWithdraw;
