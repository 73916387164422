import { useNavigate } from 'react-router';

import { getCasinoProviderIcon } from 'utils/icons';
import { getProviderGamesPath } from 'Routes/utils';

import styles from './ProviderBox.module.css';

type Props = {
  name: string;
  id: number;
};

const ProviderBox = ({ name, id }: Props) => {
  const navigate = useNavigate();

  const jumpTo = () => {
    navigate(
      getProviderGamesPath({
        page: '1',
        scroll: '0',
        providerNames: [name],
      })
    );
  };

  return (
    <div className={styles.providerItem} onClick={jumpTo}>
      {getCasinoProviderIcon(name, styles.icon)}
    </div>
  );
};

export default ProviderBox;
