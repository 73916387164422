import { useCasinoGames } from 'react-render-hooks';
import cln from 'classnames';
import { CasinoGamesResponse } from 'react-memory-optimization/dist/lib/store/casino/entities/games/types';

import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import CasinoGame from 'components/CasinoComponents/CasinoGame';
import { ShowAllCard } from 'react-gldn-kit';

import { CASINO_LIMIT_GAMES } from 'constants/transport';
import { APP_TYPE, IS_VERTICAL } from 'constants/ui';

import {
  getDeviceTypeSelector,
  isMobileDeviceSelector,
} from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';

import { EDeviceType } from 'stores/device/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './RowGames.module.css';

type Props = {
  hookProps: {
    subCategoryId?: number | null;
    categoryId?: number | null;
    page: number;
    search: string;
    providerId?: number | null;
  };
  jumpTo: () => void;
  classes?: string;
};

const FACTOR_MOBILE: [number, number] = [3.3, 2.2];

const FACTOR_TABLET: [number, number] = [5.3, 4.2];

const FACTOR_DESKTOP: [number, number] = [9.8, 8];

const RowGames = ({ hookProps, jumpTo, classes }: Props) => {
  const device = useZustandDeviceStore(getDeviceTypeSelector);

  const gameInfo = useHookFabric<CasinoGamesResponse>({
    fn: useCasinoGames,
    customProps: {
      ...hookProps,
      limit: CASINO_LIMIT_GAMES,
    },
  });

  if (!gameInfo?.games) {
    return (
      <div className={styles.rowCasinoGamesWrapper}>
        <SkeletLoader />
      </div>
    );
  }

  let factors = FACTOR_MOBILE;

  if (device === EDeviceType.Tablet) {
    factors = FACTOR_TABLET;
  }

  if (device === EDeviceType.Desktop) {
    factors = FACTOR_DESKTOP;
  }

  return (
    <div
      className={cln(styles.rowCasinoGamesWrapper, classes)}
      style={{
        gridAutoColumns: `${Math.floor(
          window.innerWidth / (IS_VERTICAL ? factors[0] : factors[1])
        )}px`,
      }}
    >
      {gameInfo?.games.map((g: any) => (
        <CasinoGame {...g} key={g.id} />
      ))}

      <ShowAllCard appType={APP_TYPE} handleClick={jumpTo} />
    </div>
  );
};

export default RowGames;
