import cln from 'classnames';

import { PlaySvg } from 'react-gldn-kit';

import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import CircularLoader from 'components/ui/Loaders/Circular';

import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';

import { useAuth } from 'react-render-hooks';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { useHookFabric } from 'hooks/useHookFabric';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './PlayButton.module.css';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { getActiveBalanceInfoSelector } from 'stores/user/balance/selectors';

type Props = {
  handleClick: () => void;
  classes?: string;
};

const PlayButton = ({ handleClick, classes }: Props) => {
  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });
  const setModal = useZustandModalStore(setModalInfoAction);

  const balanceInfo = useZustandBalanceStore(getActiveBalanceInfoSelector);

  const play = () => {
    if (!isAuth) {
      setModal(ModalTypes.SignIn);

      return;
    }

    if (balanceInfo.balance <= 0) {
      setModal(ModalTypes.MoneyOperations);

      return;
    }
    handleClick();
  };

  return (
    <div className={cln(styles.wrapperPlayButton, classes)} onClick={play}>
      <HOCRequestLoader
        requestId={ERequestIds.InitCasinoGame}
        command="\\17x"
        customLoader={<CircularLoader />}
      >
        <PlaySvg />
      </HOCRequestLoader>
    </div>
  );
};

export default PlayButton;
