import { useEffect, useState } from 'react';
import cln from 'classnames';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';

import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import { Text, CopySvg } from 'react-gldn-kit';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';

import { useZustandFormsStore } from 'stores/money/forms/store';
import {
  getBdtStepTwoInfoSelector,
  setFormsInfoAction,
} from 'stores/money/forms/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';

import styles from './Rocket.module.css';
import depositBase from '../Deposit.module.css';

import {
  composeValidations,
  extractNumbersFromString,
  isRequired,
} from 'utils/validation';
import { copyToClipboard } from 'utils/shared';

type Props = {
  limit: { min: number; max: number };
};

const Rocket = ({ limit }: Props) => {
  const bdtTwoStepInfo = useZustandFormsStore(getBdtStepTwoInfoSelector);

  const [transactionId, setTransactionId] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState<string>('');
  const validationDocumentId = (value: string) => {
    let errorText =
      composeValidations([
        isRequired({ value, errorText: 'errors.required' }),
      ]) || '';

    if (!errorText && value.length !== 10) {
      errorText = 'errors.rocketTransactionIdLength';
    }

    setTransactionId({
      value,
      errorText,
    });
  };

  useEffect(() => {
    if (amount && currencyId) {
      setStep(Steps.BdtStepTwo);
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          currencyId,
          channelId: PaymentChannelType.Rocket,
          requestId: ERequestIds.GetBillingAddress,
        },
      });
      //
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId]);

  useEffect(() => {
    if (!amount || !currencyId || !bdtTwoStepInfo) {
      return;
    }

    if (!transactionId.errorText && transactionId.value) {
      setFormInfo({
        isDeposit: true,
        form: {
          transaction: transactionId.value,
          amount,
          channelId: PaymentChannelType.Rocket,
          currencyId,
          requestId: ERequestIds.RequestDeposit,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [transactionId, currencyId, amount, bdtTwoStepInfo]);

  return (
    <div>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        disabled={Boolean(bdtTwoStepInfo)}
        wrapperClasses={depositBase.marginInputBottom}
        classes={depositBase.amountInputMargin}
      />

      {bdtTwoStepInfo && (
        <>
          <Input
            defaultValue={bdtTwoStepInfo.billingAccount}
            placeholder="walletPopUp.forms.billingAccount"
            size="full"
            inputStyleType="border-colorful"
            inputLabelType="labelUp"
            disabled
            onChange={console.info}
            classes={cln(
              styles.billingAccountInput,
              depositBase.marginInputBottom
            )}
          >
            <CopySvg
              classes={styles.copyIcon}
              handleClick={copyToClipboard(bdtTwoStepInfo.billingAccount)}
            />
          </Input>

          <div className={cln(depositBase.marginInputBottom, styles.bdtInfo)}>
            <Text idT="walletPopUp.forms.bdtInfo" />
          </div>

          <Input
            defaultValue=""
            placeholder="walletPopUp.forms.transactionId"
            inputStyleType="border-colorful"
            inputLabelType="labelUp"
            size="full"
            errorText={transactionId.errorText}
            onChange={validationDocumentId}
            patternFn={extractNumbersFromString}
            classes={depositBase.marginInputBottom}
          />
        </>
      )}
    </div>
  );
};

export default Rocket;
