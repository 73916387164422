import { useEffect } from 'react';

import { Text } from 'react-gldn-kit';

import { setDepositBonusIdForActivateAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';

import styles from './BonusContent.module.css';

type Props = {
  order: number;
  minDeposit: number;
  id: number;
  bonusPromotion: string;
  isBonusOnDeposit?: boolean;
  isActive?: boolean;
};

const BonusContent = (props: Props) => {
  const {
    minDeposit,
    order,
    isActive = false,
    bonusPromotion,
    isBonusOnDeposit = false,
    id,
  } = props;

  const setBonusId = useZustandFormsStore(setDepositBonusIdForActivateAction);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    setBonusId(id);
  }, [isActive, id]);

  return (
    <div
      className={styles.bonusWrapper}
      style={{
        background: isBonusOnDeposit
          ? 'var(--gradient-bonus-on-deposit)'
          : 'var(--gradient-bonus-free-spin)',
      }}
    >
      <div>
        <div className={styles.bonusName}>
          <Text idT="walletPopUp.bonuses" specValue={`${order}`} />
        </div>
        <div className={styles.bonusMinDep}>
          <Text idT={`${minDeposit || 'walletPopUp.welcomeBonus'}`} />
        </div>
      </div>
      <div className={styles.bonusOffer}>{bonusPromotion}</div>
    </div>
  );
};

export default BonusContent;
