import React from 'react';
import { Text } from 'react-gldn-kit';

import styles from './PromoCodeRow.module.css';

export type PromoCodeRowProps = {
  title: string;
  value: string | number;
};

const PromoCodeRow = ({ title, value }: PromoCodeRowProps) => (
  <div className={styles.row}>
    <div className={styles.name}>
      <Text idT={title} />
    </div>

    <div className={styles.value}>
      <Text idT={value.toString()} />
    </div>
  </div>
);

export default PromoCodeRow;
