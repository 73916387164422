import React, { useState } from 'react';
import { PopUpHeader, useTranslate } from 'react-gldn-kit';

import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import Tickets from 'components/Betting/Tickets';

import { TABS } from './constants';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';
import { ActiveTicketTypeTab } from './types';

import styles from './InGameTickets.module.css';

const InGameTickets = () => {
  const [activeTab, setActiveTab] = useState<ActiveTicketTypeTab>(
    ActiveTicketTypeTab.All
  );

  const { getText } = useTranslate('', '');

  const closeModal = useZustandModalStore(setModalInfoAction);

  const onClose = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <div className={styles.wrapperInGameTickets}>
      <PopUpHeader
        title={getText('T.tickets')}
        classes={styles.header}
        closeButton={onClose}
      />

      <ComponentKitTabs
        activeValue={activeTab}
        data={TABS}
        onChange={setActiveTab}
        classes={styles.tabs}
      />

      <Tickets
        classes={styles.content}
        isAll={activeTab === ActiveTicketTypeTab.All}
        isCashOut={activeTab === ActiveTicketTypeTab.CashOut}
        isLive={activeTab === ActiveTicketTypeTab.Live}
      />
    </div>
  );
};

export default InGameTickets;
