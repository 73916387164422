import { useEffect, useState } from 'react';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import {
  CashXMethods,
  PaymentChannelType,
  WellexPayMethods,
} from 'react-memory-optimization/dist/lib/store/user/types';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import depositStyles from '../../Deposit.module.css';
import AmountInput from 'components/ui/Input/AmountInput';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { Steps } from 'stores/money/payments/types';

// TODO default ?
type Props = {
  channelId:
    | WellexPayMethods
    | CashXMethods
    | (PaymentChannelType.BkashXoomPay | PaymentChannelType.NagadXoomPay)
    | PaymentChannelType.PlixoNagad
    | PaymentChannelType.NagadGldn;
  limit: { min: number; max: number };
};

const WellexPayNagadBkash = ({ channelId, limit }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  useEffect(() => {
    if (currencyId && amount) {
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          currencyId,
          channelId,
          requestId: ERequestIds.RequestDeposit,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [currencyId, channelId, amount]);

  return (
    <AmountInput
      inputStyleType="border-colorful"
      inputLabelType="labelUp"
      placeholder="walletPopUp.forms.amount"
      size="full"
      onChange={setAmount}
      min={limit.min}
      max={limit.max}
      classes={depositStyles.amountInputMargin}
      wrapperClasses={depositStyles.marginInputBottom}
    />
  );
};

export default WellexPayNagadBkash;
