import cln from 'classnames';

import { Text } from 'react-gldn-kit';
import ButtonLanguage from 'components/ui/Buttons/ButtonLanguage';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import styles from './AuthButtons.module.css';

const AuthButtons = () => {
  const setModalType = useZustandModalStore(setModalInfoAction);

  const setModalInfo = (type: ModalTypes) => () => {
    setModalType(type);
  };

  return (
    <div className={cln(styles.wrapperAuthButtons, styles.row)}>
      <ButtonLanguage />

      <div className={cln(styles.marginLeft, styles.row)}>
        <div
          className={styles.btnText}
          onClick={setModalInfo(ModalTypes.SignIn)}
        >
          <Text idT="header.login" />
        </div>

        <div
          className={cln(styles.marginLeft, styles.btnText)}
          onClick={setModalInfo(ModalTypes.SignUp)}
        >
          <Text idT="header.join" />
        </div>
      </div>
    </div>
  );
};

export default AuthButtons;
