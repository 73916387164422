import AvailableForWithdraw from '../AvailableForWithdraw';
import DefaultWithdraw from '../DefaultWithdraw';
import CriptoWithdraw from '../CriptoWithdraw';
import BankPixWithdraw from '../BankPixWithdraw';
import PixWithdraw from '../PixWithdraw';
import PixPagsmileWithdraw from '../PixPagsmileWithdraw';
import PixBankTransferWithdraw from '../PixBankTransferWithdraw';
import CoinCriptoWithdraw from '../CoinCriptoWithdraw';
import TurkishPapara from '../Turkish/Papara';
import TurkishAnindaHavale from '../Turkish/TurkishAnindaHavale';
import MoneticsBKashWithdraw from '../Monetics/BKash';
import MoneticsNagadWithdraw from '../Monetics/Nagad';
import TurkishHavaleHera from '../Turkish/HavaleHera';
import ExpressHavale from '../Turkish/ExpressHavale';
import VipHavale from '../Turkish/VipHavale';
import TurkishMpayPayFix from '../Turkish/TurkishMpayPayFix';
import TurkishMPayMefeteOrParazula from '../Turkish/MPayMethods/TurkishMPayMefeteOrParazula';
import TurkishMPayEft from '../Turkish/MPayMethods/Eft';
import TurkishMPayPopy from '../Turkish/MPayMethods/Popy';
import WellexPay from '../WellexPay';
import RublesDefault from '../Rubles/RublesDefault';
import InrWithdraw from '../Inr';

import {
  PaymentChannelType,
  RublesMethods,
} from 'react-memory-optimization/dist/lib/store/user/types';
import {
  COINPAY_CRYPTO,
  RUBLES,
} from 'react-memory-optimization/dist/lib/store/user/constants';

import styles from './WithdrawSkelet.module.css';
import InrOctopay from '../Inr/Octopay';
import AznWithdraw from '../Azn/CashXAzn';

type Props = {
  activeChannelId: number;
  limit: { min: number; max: number };
};

const WithdrawSkeleton = ({ limit, activeChannelId }: Props) => {
  console.log('🚀 :', activeChannelId);

  const { min, max } = limit;

  return (
    <div className={styles.wrapperWithdrawForms}>
      <AvailableForWithdraw />
      {/* @ts-ignore */}
      {RUBLES.includes(activeChannelId) && (
        <RublesDefault
          key={activeChannelId}
          min={min}
          max={max}
          channelId={activeChannelId as RublesMethods}
        />
      )}

      {activeChannelId === PaymentChannelType.CashXAZN && (
        <AznWithdraw min={min} max={max} />
      )}

      {activeChannelId === PaymentChannelType.INRWithdrawImp && (
        <InrWithdraw channelId={activeChannelId} min={min} max={max} />
      )}

      {(activeChannelId === PaymentChannelType.INRIMPS ||
        activeChannelId === PaymentChannelType.INRNEFTS ||
        activeChannelId === PaymentChannelType.INRRGTS) && (
        <InrOctopay channelId={activeChannelId} min={min} max={max} />
      )}

      {(activeChannelId === PaymentChannelType.BKashFastWellexPay ||
        activeChannelId === PaymentChannelType.WellxpayUpay ||
        activeChannelId === PaymentChannelType.NagadFastWellexPayCopy ||
        activeChannelId === PaymentChannelType.BKashFastWellexPayCopy ||
        activeChannelId === PaymentChannelType.WellxpayRocket ||
        activeChannelId === PaymentChannelType.NagadFastWellexPay ||
        activeChannelId === PaymentChannelType.BkashXoomPay ||
        activeChannelId === PaymentChannelType.GLDNBKash ||
        activeChannelId === PaymentChannelType.NagadGldn ||
        activeChannelId === PaymentChannelType.NagadXoomPay ||
        // Test
        activeChannelId === PaymentChannelType.NagadCashX ||
        activeChannelId === PaymentChannelType.BkashCashX) && (
        <WellexPay channelId={activeChannelId} min={min} max={max} />
      )}

      {PaymentChannelType.MefeteMpay === activeChannelId ||
        (activeChannelId === PaymentChannelType.MPayParazula && (
          <TurkishMPayMefeteOrParazula
            min={min}
            max={max}
            activeChannelId={activeChannelId}
          />
        ))}
      {PaymentChannelType.EFTMPay === activeChannelId && (
        <TurkishMPayEft min={min} max={max} />
      )}
      {PaymentChannelType.PopyMpay === activeChannelId && (
        <TurkishMPayPopy min={min} max={max} />
      )}

      {(PaymentChannelType.MPayFix === activeChannelId ||
        activeChannelId === PaymentChannelType.PaparaMPay ||
        activeChannelId === PaymentChannelType.MPayPayco) && (
        <TurkishMpayPayFix min={min} max={max} channelId={activeChannelId} />
      )}

      {PaymentChannelType.TurkishPapara === activeChannelId && (
        <TurkishPapara min={min} max={max} />
      )}
      {PaymentChannelType.VipHavale === activeChannelId && (
        <VipHavale min={min} max={max} />
      )}
      {PaymentChannelType.TurkishExpressHavale === activeChannelId && (
        <ExpressHavale min={min} max={max} />
      )}
      {PaymentChannelType.HavaleHera === activeChannelId && (
        <TurkishHavaleHera min={min} max={max} />
      )}
      {PaymentChannelType.AnindaHavale === activeChannelId && (
        <TurkishAnindaHavale min={min} max={max} />
      )}
      {PaymentChannelType.PixPagsmile === activeChannelId && (
        <PixPagsmileWithdraw min={min} max={max} />
      )}

      {PaymentChannelType.BankTransfer === activeChannelId && (
        <PixBankTransferWithdraw min={min} max={max} />
      )}

      {[
        PaymentChannelType.TRC,
        PaymentChannelType.BEP,
        PaymentChannelType.ERC,
        PaymentChannelType.SOLANA,
      ].includes(activeChannelId) && (
        <CriptoWithdraw min={min} max={max} channelId={activeChannelId} />
      )}

      {PaymentChannelType.BankPix === activeChannelId && (
        <BankPixWithdraw min={min} max={max} />
      )}

      {PaymentChannelType.Pix === activeChannelId && (
        <PixWithdraw min={min} max={max} />
      )}

      {PaymentChannelType.BkashMonetics === activeChannelId && (
        <MoneticsBKashWithdraw min={min} max={max} />
      )}

      {PaymentChannelType.NagadMonetics === activeChannelId && (
        <MoneticsNagadWithdraw min={min} max={max} />
      )}

      {/* {COINPAY_CRIPTO} */}
      {COINPAY_CRYPTO.includes(activeChannelId) && (
        <CoinCriptoWithdraw min={min} max={max} channelId={activeChannelId} />
      )}

      {/* DEFAULT */}
      {[
        PaymentChannelType.OctopayRocket,
        PaymentChannelType.OctopayUPay,
        PaymentChannelType.OctopayBKash,
        PaymentChannelType.OctopayNagad,
        PaymentChannelType.VivaroBkash,
        PaymentChannelType.VivaroNagad,
      ].includes(activeChannelId) && (
        <DefaultWithdraw min={min} max={max} channelId={activeChannelId} />
      )}

      {(activeChannelId === PaymentChannelType.BKash ||
        activeChannelId === PaymentChannelType.ApayBkash) && (
        <DefaultWithdraw min={min} max={max} channelId={activeChannelId} />
      )}

      {(activeChannelId === PaymentChannelType.Nagad ||
        PaymentChannelType.UPay === activeChannelId ||
        activeChannelId === PaymentChannelType.ApayNagad) && (
        <DefaultWithdraw min={min} max={max} channelId={activeChannelId} />
      )}

      {(activeChannelId === PaymentChannelType.EasypaisaFast ||
        activeChannelId === PaymentChannelType.Easypaisa ||
        activeChannelId === PaymentChannelType.JazzCash) && (
        <DefaultWithdraw min={min} max={max} channelId={activeChannelId} />
      )}

      {activeChannelId === PaymentChannelType.Rocket && (
        <DefaultWithdraw
          min={min}
          max={max}
          channelId={PaymentChannelType.Rocket}
        />
      )}
    </div>
  );
};

export default WithdrawSkeleton;
