import { useEffect, useRef, useState } from 'react';

import BaseInputUi from './BaseInputUi';

import { InputBaseProps } from './types';
import { useTranslate } from 'react-gldn-kit';

const Input = (props: InputBaseProps) => {
  const {
    children,
    defaultValue = '',
    onChange,
    onFocus,
    patternFn,
    hardSetValue = false,
    baseValue = '',
  } = props;

  const { name } = useTranslate(defaultValue || baseValue, '');

  const [value, setValue] = useState(name);

  const [isFocused, setFocusState] = useState(false);

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    const { value } = target;

    const newValue = patternFn ? patternFn(value) : value;

    if (baseValue && !newValue.includes(baseValue)) {
      setValue(baseValue);

      return;
    }

    setValue(newValue);

    timer.current = setTimeout(() => {
      onChange(newValue);
    }, 300);
  };

  const handleFocus = () => {
    setFocusState(true);

    onFocus?.();
  };

  const handleBlure = () => {
    setFocusState(false);
  };

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    []
  );

  useEffect(() => {
    // if (typeof hardSetValue !== 'boolean') { --- BET SLIP
    if (hardSetValue) {
      const newValue = patternFn ? patternFn(hardSetValue) : hardSetValue;

      setValue(newValue);
      onChange(newValue);
    }
  }, [hardSetValue, onChange]);

  // isFocused
  return (
    <BaseInputUi
      {...props}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleBlure={handleBlure}
      value={value}
      isFocused={isFocused}
    >
      {children}
    </BaseInputUi>
  );
};

export default Input;
