import { useState } from 'react';
import { ModalLayout } from 'react-gldn-kit';

import ProviderSearch from './components/ProviderSearch';
import ProviderList from './components/ProviderList';

const CasinoProviders = () => {
  const [searchProviderValue, setSearchProviderValue] = useState<string>('');

  return (
    <ModalLayout>
      <>
        <ProviderSearch setSearchProviderValue={setSearchProviderValue} />

        <ProviderList
          searchValue={searchProviderValue}
          // onlyWagering={+categoryId === ECasinoCategoryIds.Wager}
          onlyWagering={window.location.href.includes('-1')}
        />
      </>
    </ModalLayout>
  );
};

export default CasinoProviders;
