import { useZustandLanguagesKitStore, languageSelectors } from 'react-gldn-kit';
import { getFlagIcon } from 'utils/icons';

import styles from './ButtonLanguage.module.css';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

type Props = any;

const ButtonLanguage = (props: Props) => {
  const activeLanguageName = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const handleOpenPopUp = () => {
    setModal(ModalTypes.Languages);
  };

  return (
    <div className={styles.wrapperButtonLanguage} onClick={handleOpenPopUp}>
      {activeLanguageName ? getFlagIcon(activeLanguageName) : 'loading'}
    </div>
  );
};

export default ButtonLanguage;
