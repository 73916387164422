import AmountInput from 'components/ui/Input/AmountInput';
import { useEffect, useState } from 'react';

import { useZustandFormsStore } from 'stores/money/forms/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { PaymentChannelType } from 'react-memory-optimization/dist/lib/store/user/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { ERequestIds } from 'react-memory-optimization/dist/lib/binary/types';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { Steps } from 'stores/money/payments/types';

import styles from '../Deposit.module.css';

type Props = {
  channelId:
    | PaymentChannelType.NagadApi
    | PaymentChannelType.ApayNagad
    | PaymentChannelType.EasypaisaFast
    | PaymentChannelType.Easypaisa
    | PaymentChannelType.UPay
    | PaymentChannelType.JazzCash;
  limit: {
    min: number;
    max: number;
  };
};

const NagadApiFast = ({ limit, channelId }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  useEffect(() => {
    if (amount && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          transaction: '',
          amount,
          channelId,
          currencyId,
          requestId: ERequestIds.RequestDeposit,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId]);

  return (
    <AmountInput
      placeholder="walletPopUp.forms.amount"
      size="full"
      onChange={setAmount}
      min={limit.min}
      max={limit.max}
      inputStyleType="border-colorful"
      inputLabelType="labelUp"
      wrapperClasses={styles.marginInputBottom}
      classes={styles.amountInputMargin}
    />
  );
};

export default NagadApiFast;
