import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  useBettingAvailableStatus,
  useBettingSettingStatus,
  useTransportControllers,
} from 'react-render-hooks';

import BettingSkelet from 'components/ui/Loaders/BettingSkelet';
import BetSlipButton from 'components/ui/Buttons/BetSlipButton';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { isFrameBettingSelector } from 'stores/configuration/selectors';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './HOCBettingStartSubscribe.module.css';

const HOCBettingStartSubscribe = () => {
  const isAvailableTransport = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isBettingLoaded = useHookFabric<boolean>({
    fn: useBettingAvailableStatus,
  });

  const isPreparedBetting = useHookFabric<boolean>({
    fn: useBettingSettingStatus,
  });

  const isFrame = useZustandWebConfiguration(isFrameBettingSelector);

  const { sendCommand } = useTransportControllers();

  useEffect(() => {
    if (!isAvailableTransport || isFrame || isPreparedBetting) {
      return;
    }

    sendCommand('47x', {});
  }, [isAvailableTransport, isFrame, isPreparedBetting]);

  useEffect(
    () => () => {
      sendCommand('48x', {});
    },
    []
  );

  if (isFrame) {
    return <Outlet />;
  }

  if (!isBettingLoaded) {
    return <BettingSkelet />;
  }

  return (
    <div className={styles.wrapperBetting}>
      <Outlet />

      {isMobile && (
        <Suspense fallback={<BettingSkelet />}>
          <BetSlipButton />
        </Suspense>
      )}
    </div>
  );
};

export default HOCBettingStartSubscribe;
