import { ECasinoTournamentStatus } from 'react-memory-optimization/dist/lib/store/casino/entities/casinoTournaments/types';

export const DEFAULT_TOURNAMENT = {
  amount: 0,
  endDate: new Date().getTime(),
  id: 0,
  players: [],
  startDate: new Date().getTime(),
  status: ECasinoTournamentStatus.Finished,
};

export const DEFAULT_TOP_PLAYERS = [
  { position: 1, payout: 0, payoutBonus: 0, rolled: 0, id: 1 },
  { position: 2, payout: 0, payoutBonus: 0, rolled: 0, id: 2 },
  { position: 3, payout: 0, payoutBonus: 0, rolled: 0, id: 3 },
];
