import cln from 'classnames';
import { useTickets } from 'react-render-hooks';
import {
  ETicketType,
  Ticket,
} from 'react-memory-optimization/dist/lib/store/betting/entities/tickets/types';

import OrdinarTicket from './components/OrdinarTicket';
import ExpressTicket from './components/ExpressTicket';
import SystemTicket from './components/SystemTicket';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './Tickets.module.css';

type Props = {
  classes?: string;
  isAll: boolean;
  isCashOut: boolean;
  isLive: boolean;
};

const Tickets = (props: Props) => {
  const { isAll, isCashOut, isLive, classes } = props;

  const tickets = useHookFabric<Ticket[]>({
    fn: useTickets,
    customProps: {
      isAll,
      isCashOut,
      isLive,
    },
  });

  return (
    <div className={cln(styles.wrapperTickets, classes)}>
      {tickets?.map((ticket) => {
        const {
          id,
          timestamp,
          cashoutRecords,
          stake,
          type,
          acceptedOdd,
          ordinars,
        } = ticket;

        if (type === ETicketType.Express) {
          return (
            <ExpressTicket
              key={id}
              timestamp={timestamp}
              ticketId={id}
              cashoutRecords={cashoutRecords}
              stake={stake}
              ordinars={ordinars}
              acceptedOdd={acceptedOdd}
              winAmount={+(stake * +(acceptedOdd - 1).toFixed(2)).toFixed(2)}
            />
          );
        }

        if (type === ETicketType.System) {
          return (
            <SystemTicket
              key={id}
              timestamp={timestamp}
              ticketId={id}
              cashoutRecords={cashoutRecords}
              stake={stake}
              rang={ticket.systemRang}
              ordinars={ordinars}
              acceptedOdd={acceptedOdd}
              winAmount={+(stake * +(acceptedOdd - 1).toFixed(2)).toFixed(2)}
            />
          );
        }

        return (
          <OrdinarTicket
            key={id}
            timestamp={timestamp}
            ticketId={id}
            cashoutRecords={cashoutRecords}
            stake={stake}
            // TODO tofixed remove
            winAmount={+(stake * +(acceptedOdd - 1).toFixed(2)).toFixed(2)}
            {...ordinars[0]}
          />
        );
      })}
    </div>
  );
};

export default Tickets;
