import React from 'react';
import { useBetSlipSpecialOdd } from 'react-render-hooks';

import { useHookFabric } from 'hooks/useHookFabric';

const ExpressOdd = () => {
  const odd = useHookFabric<{
    express: string;
  }>({
    fn: useBetSlipSpecialOdd,
    customProps: { expressOdd: true },
  });

  return <div>{odd?.express}</div>;
};

export default ExpressOdd;
