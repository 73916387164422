import { Provider } from 'react-memory-optimization/dist/lib/store/casino/entities/providers/types';

import ProviderItem from '../ProviderItem';

import styles from './ProviderBlock.module.css';

type Props = {
  providerByLetter: (Provider & { gameCounter: number })[];
  letter: string;
};

const ProviderBlock = ({ providerByLetter, letter }: Props) => (
  <div className={styles.wrapperStyles}>
    <div className={styles.providerLetter}>{letter}</div>

    {providerByLetter.map((p) => (
      <ProviderItem
        key={p.id}
        name={p.name}
        id={p.id}
        gameCounter={p.gameCounter}
      />
    ))}
  </div>
);

export default ProviderBlock;
