import React, { useEffect, useRef, useState } from 'react';
import { RegistrationSubType } from 'react-memory-optimization/dist/lib/binary/types';

import { useAuth, useTransportControllers } from 'react-render-hooks';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import {
  IS_DEVELOPMENT_MODE,
  IS_TG_DEBUG,
  TELEGRAM_CHAT_ID,
} from 'constants/ui';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getClientIdSelector } from 'stores/user/personal/selectors';
import { ENCRYPT_DATA } from 'constants/marketingTags';
import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import {
  getActiveCountryIdSelector,
  getActiveCurrencyIdSelector,
  getAvailableCurrenciesSelector,
} from 'stores/forms/signUp/selectors';

import CircularLoader from 'components/ui/Loaders/Circular';

import { getRegistrationData } from 'utils/transport/main';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './HOCTelegram.module.css';

type Props = {
  children: React.ReactElement;
};

const HOCTelegram = ({ children }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const userInfo = useAuth({ isConnectedApp });

  const clientId = useZustandUserStore(getClientIdSelector);

  const [isRegistrationTG, setRegistrationTg] = useState(false);

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const availableCurrencies = useZustandRegistrationStore(
    getAvailableCurrenciesSelector
  );

  const { sendCommand } = useTransportControllers();

  const activeCountryId = useZustandRegistrationStore(
    getActiveCountryIdSelector
  );

  const activeCurrencyId = useZustandRegistrationStore(
    getActiveCurrencyIdSelector
  );

  const saveToken = async (token: string, clientId: number) => {
    try {
      const url = IS_DEVELOPMENT_MODE
        ? 'http://localhost:8001'
        : `${window.location.origin}/tgb`;

      const res = await fetch(`${url}/user/assign-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'telegram-id': TELEGRAM_CHAT_ID,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ clientId }),
      });

      await res.json();
    } catch (error) {
      console.error('🚀 :', error);
    }
  };

  useEffect(() => {
    if (!userInfo || !TELEGRAM_CHAT_ID || !clientId) {
      return;
    }

    saveToken(userInfo.token, clientId);
  }, [userInfo, clientId]);

  useEffect(() => {
    if (
      isConnectedApp &&
      TELEGRAM_CHAT_ID &&
      !userInfo &&
      !clientId &&
      !ENCRYPT_DATA
    ) {
      setRegistrationTg(true);
    }
  }, [isConnectedApp, userInfo, clientId]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (
      isConnectedApp &&
      TELEGRAM_CHAT_ID &&
      !userInfo &&
      !clientId &&
      !ENCRYPT_DATA &&
      isRegistrationTG &&
      availableCurrencies.length
    ) {
      const data = getRegistrationData({
        countryId: activeCountryId || 50,
        currencyId: activeCurrencyId || availableCurrencies[0].id,
        registrationType: RegistrationSubType.OneClick,
        promoCode: '',

        countryName: `${activeCountryId || 50}`, // TODO back end is not validate this value
      });

      if (IS_TG_DEBUG) {
        alert(JSON.stringify({ data }));
        alert(`${activeCountryId}_COUNTRY`);
        alert(`${activeCurrencyId}_CURRENCY_ID`);
        alert(`${availableCurrencies[0].id}_INIT`);
      }

      sendCommand('05x', data, true);

      return;
    }
  }, [
    isRegistrationTG,
    isConnectedApp,
    userInfo,
    clientId,
    activeCountryId,
    activeCurrencyId,
    availableCurrencies,
  ]);

  useEffect(() => {
    if (userInfo) {
      setRegistrationTg(false);

      return;
    }
  }, [userInfo]);

  return (
    <>
      {children}

      {isRegistrationTG && !userInfo && (
        <div className={styles.wrapperLoader}>
          <CircularLoader />
        </div>
      )}
    </>
  );
};

export default HOCTelegram;
